<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onDomainAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Domain</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="link" name="link" v-validate="'required'"
                                           v-model="domain.link" type="text" class="validate">
                                    <label :class="{active:domain.link}" for="link">Link</label>
                                    <span class="error-text">{{ errors.first('link') }}</span>
                                    <span v-if="serverErrors.link" class="helper-text red-text">{{ serverErrors.link[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
    export default {
        name: "BlockDomain",
        data() {
            return {
                modal: null,
                serverErrors: [],
                gridColumns: [{
                    label: 'id',
                    field: 'id',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Link',
                        field: 'link',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                ],
                api: '/api/copy-paste/block-domain',
                header: '',
                domain: {
                    id: null,
                    link: ''
                }
            }
        },
        mounted() {
            const elem = document.getElementById('user-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        methods: {
            onAddItem() {
                this.domain = {id: null, link: ''};
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onDomainAdd(event) {
                this.serverErrors=[];
                this.$validator.validate().then(result => {
                    if (result) {
                        return axios.post(this.api, this.domain)
                            .then(({data}) => {
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                            }).catch(({response}) => {
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;
                                }
                            })
                    }
                });

            },
            onEditItem(item) {
                this.domain = {
                    id: item.id,
                    link: item.link
                };
                this.header = "Edit";
                this.modal.open();

            }
        }
    }
</script>

<style scoped>

</style>