<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="card">
        <div class="card-content">
            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#5661b3"/>
            <div class="row">
                <form class="col s6" @submit.prevent="onSubmit">
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="error_1" name="error_1" v-validate="'required'"
                                   v-model="webcrawler.error_1" type="text" class="validate">
                            <label :class="{active:webcrawler.error_1}" for="error_1">Error 1</label>
                            <span class="error-text">{{ errors.first('error_1') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="error_2" name="error_2" v-validate="'required'"
                                   v-model="webcrawler.error_2" type="text" class="validate">
                            <label :class="{active:webcrawler.error_2}" for="error_1">Error 2</label>
                            <span class="error-text">{{ errors.first('error_2') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="error_3" name="error_3" v-validate="'required'"
                                   v-model="webcrawler.error_3" type="text" class="validate">
                            <label :class="{active:webcrawler.error_3}" for="error_1">Error 3</label>
                            <span class="error-text">{{ errors.first('error_3') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="error_4" name="error_4" v-validate="'required'"
                                   v-model="webcrawler.error_4" type="text" class="validate">
                            <label :class="{active:webcrawler.error_4}" for="error_1">Error 4</label>
                            <span class="error-text">{{ errors.first('error_4') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="error_5" name="error_5" v-validate="'required'"
                                   v-model="webcrawler.error_5" type="text" class="validate">
                            <label :class="{active:webcrawler.error_5}" for="error_1">Error 5</label>
                            <span class="error-text">{{ errors.first('error_5') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s6">
                            <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                                <i class="material-icons right">send</i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WebCrawlerError",
        data() {
            return {
                webcrawler: {
                    id: null
                },
                isLoading: true
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                axios.get('/api/copy-paste/webcrawler-error')
                    .then(({data}) => {
                        this.webcrawler = data;
                        this.isLoading = false;
                    });
            },
            onSubmit() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.isLoading = true;
                        return axios.post('/api/copy-paste/webcrawler-error', this.webcrawler)
                            .then(({data}) => {
                                this.webcrawler = data;
                                this.isLoading = false;
                            });
                    }
                });
            }
        }
    }
</script>