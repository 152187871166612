<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="row">
        <div class="col s12">
            <grid ref="grid"
                  :api="api"
                  :selectable="true"
                  :searchFilterArray="searchFilterArray"
                  :isCustomButton="true"
                  :customButton="customButtom"
                  @customBtn="onApprove"
                  :columns="gridColumns">
            </grid>
        </div>
    </div>
</template>

<script>
import {serverBus} from '../../serverBus';

export default {
    name: "HireTalent",
    data() {
        return {
            customButtom: {
                name: "Approve",
                icon: "done"
            },
            searchFilterArray: [
                {
                    label: 'From Date',
                    field: 'from_date',
                    type: Date

                },
                {
                    label: 'To Date',
                    field: 'to_date',
                    type: Date

                },
            ],
            gridColumns: [
                {
                    label: 'Member ID',
                    field: 'memberid',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'Title',
                    field: 'title',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'TalentMemberId',
                    field: 'talent_memberid',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'TalentMemberId',
                    field: 'talent_name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'Talent',
                    field: 'talent_title',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'Date',
                    field: 'created_at',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'Select',
                    filterOptions: {
                        enabled: true,
                        type: 'select',
                        selectData: [
                            {name: 'Approved', value: "1"},
                            {name: 'Pending', value: "2"},
                            {name: 'Deleted/Rejected', value: "3"}
                        ]
                    }
                },

            ],
            api: '/api/hire-talent/grid'
        }
    },
    mounted() {
        serverBus.$on('templateRefresh', (server) => {
            this.$refs.grid.fetch();
        });

    },
    methods: {

        onApprove(item) {
            this.$confirm('This will permanently Approve the Talent and send mail. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$refs.grid.isLoading = true;
                console.log(item);
                axios.post('/api/hire-talent/approve', {data: {id: item.id}})
                    .then(({data}) => {
                        this.isLoading = false;
                        this.$refs.grid.fetch();
                        return this.$message({
                            type: 'success',
                            message: `Mail Sent`
                        });
                    }).catch(({response}) => {
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Approve Process canceled'
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
