<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="card" id="grid">
        <div class="card-content">
            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
            <div class="row" v-if="searchFilterArray">
                <div class="input-field col s2" v-for="key in searchFilterArray">
                    <template v-if="key.type===Date">
                        <el-date-picker
                            :id="key.field"
                            :name="key.field"
                            v-model="search[key.field]"
                            type="date"
                            value-format="yyyy-MM-dd"
                            @change="datePicker"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </template>
                    <template v-else-if="key.type === 'Select'">
                        <select class="select"
                                @change="onSearchFilter(key.field,$event.target.value)">
                            <option value="null" selected>All</option>
                            <option v-for="item in key.filterOptions.selectData" :value="item.value">
                                {{ item.name }}
                            </option>
                        </select>
                    </template>
                    <template v-else>
                        <input :id="key.field" type="search" v-model="search[key.field]" :name="key.field"
                               class="validate">
                        <label :for="key.field">{{ key.label }}</label>
                    </template>
                </div>
                <div class="input-field col s3">
                    <a class="waves-effect waves-light btn" @click.prevent="searchFilter">Search</a>
                    <a class="waves-effect waves-light btn-flat" @click.prevent="resetFilter">Reset</a>
                    <a class="waves-effect waves-light btn" @click.prevent="downloadFilter">Download</a>
                </div>
            </div>
            <div class="row">
                <div class="col s6 left-align">
                    <p>Total Records: <span
                        class="chip cyan">{{ gridData.total }}</span>
                        <span style="margin-left:2px;">(Showing {{ gridData.current_page }}
                                of {{ gridData.last_page }} )</span>
                    </p>
                </div>
                <div class="input-field col s6 right-align" v-if="isAdd">
                    <a class="waves-effect waves-light btn" @click.prevent="onAdd($event)">Add</a>
                </div>
            </div>
            <div class="row">
                <div class="col s12 div-scroll">
                    <table class="striped">
                        <thead>
                        <tr>
                            <th v-if="selectable">
                            </th>
                            <th>S.No</th>
                            <th v-for="key in columns"
                                @click="key.type == String?sortBy(key.field):''"
                                :class="{ active: sortKey == key }">
                                {{ key.label | capitalize }}
                                <span v-if="key.type == String" class="arrow"
                                      :class="sortOrders[key.field] > 0 ? 'asc' : 'dsc'">
                          </span>
                            </th>
                            <th v-if="isEdit">

                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th v-if="selectable">
                                <p>
                                    <label>
                                        <input type="checkbox" class="check-box" id="select-all"
                                               @click="selectAllCheckBox($event)"/>
                                    </label>
                                </p>
                            </th>
                            <th v-for="(key,index) in columns" :key="index">
                                <template v-if="key.filterOptions.enabled">
                                    <select class="select" v-if="key.filterOptions.type === 'select'"
                                            @change="onColumnFilter(key.field,$event.target.value)">
                                        <option value="null" selected>All</option>
                                        <option v-for="item in key.filterOptions.selectData" :value="item.value">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <span v-else>
                                    <input type="text" class="column-text"
                                           @keyup.enter="onColumnFilter(key.field,$event.target.value,index)"/>
                                        <i class="material-icons tiny clear" v-show="clearIcon[index]"
                                           @click.prevent="clear(key.field,$event,index)">clear</i>
                                    </span>
                                </template>
                            </th>
                            <th v-if="isEdit">

                            </th>
                            <th v-if="isCustomButton"></th>
                            <th v-if="isDelete">

                            </th>
                            <th v-if="selectable">
                                <a class="waves-effect waves-light btn-small" @click="onDeleteAll()"><i
                                    class="material-icons left">delete_forever</i>Delete All</a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="gridData.data && gridData.data.length ==0 ">
                            No records found
                        </tr>
                        <tr v-for="(entry,index) in gridData.data">
                            <td v-if="selectable">

                                <label v-if="entry['_id']">
                                    <input type="checkbox" name="gridId" class="check-box" :value="entry['_id']"
                                           @click="selectId($event)"/>
                                </label>
                                <label v-else>
                                    <input type="checkbox" name="gridId" class="check-box" :value="entry['id']"
                                           @click="selectId($event)"/>
                                </label>
                            </td>
                            <td>{{ (index + 1) + (gridData.per_page * (gridData.current_page - 1)) }}</td>
                            <td v-for="key in columns">
                                <template v-if="key.type == Boolean">
                                    <div class="chip teal" v-if="entry[key.field]">
                                        {{ gridSelect(key.filterOptions.selectData, entry[key.field]) }}
                                    </div>
                                    <div class="chip red" v-else>
                                        {{ gridSelect(key.filterOptions.selectData, entry[key.field]) }}
                                    </div>
                                </template>
                                <template v-else-if="key.type == 'Select'">
                                    {{ gridSelect(key.filterOptions.selectData, entry[key.field]) }}
                                </template>
                                <template v-else-if="key.type == Array">
                                    <div v-html="checkArray(entry[key.field])"></div>
                                </template>
                                <template v-else-if="key.type == Date">
                                    {{ entry[key.field]|formatDate }}
                                </template>
                                <template v-else>
                                    {{ entry[key.field] }}
                                </template>
                            </td>
                            <td v-if="isEdit">
                                <a class="waves-effect waves-light btn-small" @click="onEdit(entry)"><i
                                    class="material-icons left">edit</i>Edit</a>
                            </td>
                            <td v-if="isCustomButton">
                                <a class="waves-effect waves-light btn-small" @click="onCustomButton(entry)"><i
                                    class="material-icons left">{{ customButton.icon }}</i>{{ customButton.name }}</a>
                            </td>
                            <td v-if="isDelete">
                                <a class="waves-effect waves-light btn-small" @click="onDelete(entry)"><i
                                    class="material-icons left">delete_forever</i>Delete</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" v-show="gridData.from && gridData.from != gridData.last_page">
                <div class="input-field col s2">
                    <select id="per-page-select" name="per_page" v-model="per_page" @change="page(1)">
                        <option value="10">10</option>
                        <option value="20" selected>20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <label>Rows per page</label>
                </div>
                <div class="input-field col s6">
                    <grid-pagination :pagination="gridData" @paginate="page"
                                     :offset="4"></grid-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 140px !important;
}

.el-input {
    padding-left: 31px !important;
}

table {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.column-text {
    width: 50% !important;
    margin: 5px 0 !important;
    height: 2rem !important;
    display: inline-block !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
}

th {
    padding: 0px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #2f365f;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #2f365f;
}

#grid .row {
    margin-bottom: 5px;
}

.chip {
    color: #f0f0f0 !important;
}

.clear {
    margin-left: -22px;
    position: relative;
    top: 2px;
    cursor: pointer;
}
</style>
<script>
import GridPagination from '../components/pagination';

export default {
    components: {
        GridPagination
    },
    props: {
        searchFilterArray: Array,
        columns: Array,
        api: String,
        customButton: {
            name: {
                default: "Button",
                type: String
            },
            icon: {
                default: "send",
                type: String
            }
        },
        isEdit: {
            default: false,
            type: Boolean
        },
        isDelete: {
            default: false,
            type: Boolean
        },
        isAdd: {
            default: false,
            type: Boolean
        },
        selectable: {
            default: false,
            type: Boolean,
        },
        isCustomButton: {
            default: false,
            type: Boolean
        }
    },
    data: function () {
        let sortOrders = {};
        let columnFilter = {};
        this.columns.forEach(function (key) {
            sortOrders[key.field] = 1;
            columnFilter[key.field] = null;
        });
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() >= Date.now();
                }
            },
            search: {
                from_date: new Date(),
                to_date: new Date()
            },
            sortKey: '',
            isLoading: true,
            sortOrders: sortOrders,
            columnFilter: columnFilter,
            gridData: {},
            per_page: 10,
            clearIcon: [],
            selectedId: []

        }
    },
    filters: {
        capitalize: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },

    },
    mounted() {
        const elems = document.getElementById('per-page-select');
        M.FormSelect.init(elems);
        this.sortKey = this.columns[0]['field'];
        this.sortOrder = 1;
        this.fetch();
    },
    updated() {
        this.$nextTick(() => {
            const select = document.querySelectorAll('.select');
            M.FormSelect.init(select);
        });

    },
    methods: {
        datePicker(event) {
            console.log(event);
        },
        checkArray: function (array) {
            if (_.isArray(array) || _.isObject(array)) {
                let data = '';
                _.forEach(array, function (value) {
                    data = '<span class="new badge">' + value.name + '</span>' + data;
                });
                return data;
            }
            return array;
        },
        gridSelect: function (array, id) {
            if (typeof array != "undefined") {
                const $name = array.filter(function (el) {
                    return el.value == id;
                });
                if ($name.length > 0) {
                    return $name[0].name;
                }
            }

        },
        sortBy: function (key) {
            this.isLoading = true;
            const filterKey = this.filterKey && this.filterKey.toLowerCase();
            this.sortKey = key;
            this.sortOrder = this.sortOrders[key] * -1;
            this.sortOrders[key] = this.sortOrder;
            axios.get(this.api, {
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,
                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    this.gridData = data;
                    this.isLoading = false;
                });
        },
        page(page) {
            this.isLoading = true;
            const filterKey = this.filterKey && this.filterKey.toLowerCase();
            axios.get(this.api, {
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,
                    page: page,
                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    this.gridData = data;
                    this.isLoading = false;
                });
        },
        fetch() {
            this.isLoading = true;
            /*axios.get(this.api)
                .then(({data}) => {
                    this.gridData = data;
                    this.selectedId = [];
                    this.isLoading = false;
                });*/
            this.searchFilter();
        },
        clear(key, $event, index) {
            this.unSelectAll();
            this.selectedId = [];
            ($event.target.previousElementSibling).value = '';
            this.onColumnFilter(key, '', index);

        },
        onColumnFilter(key, value, index) {
            this.unSelectAll();
            this.isLoading = true;
            this.selectedId = [];
            if (value.length > 0 && value !== String(null)) {
                this.columnFilter[key] = value;
                this.clearIcon[index] = true;
            } else {
                this.columnFilter[key] = null;
                this.clearIcon[index] = false;
            }
            const filterKey = this.filterKey && this.filterKey.toLowerCase();
            return axios.get(this.api, {
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,
                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    this.gridData = data;
                    this.isLoading = false;
                });


        },
        onSearchFilter(key, value, index) {
            this.unSelectAll();
            this.selectedId = [];
            if (value.length > 0 && value != String(null)) {
                this.search[key] = value;
            }
        },
        searchFilter() {
            this.unSelectAll();
            this.selectedId = [];
            this.isLoading = true;
            return axios.get(this.api, {
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,

                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    this.gridData = data;
                    this.isLoading = false;
                });
        },
        downloadFilter() {
            this.isLoading = true;
            return axios.get(this.api + '/download', {
                responseType: 'blob',
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,
                    page: this.gridData.current_page,
                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    console.log(data);
                    const FILE = window.URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
                    const docUrl = document.createElement('a');
                    docUrl.href = FILE;
                    docUrl.setAttribute('download', 'report_page_'+this.gridData.current_page+'.csv');
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    this.isLoading = false;
                });
        },
        resetFilter(event) {
            unSelectAll();
            this.search = {};
            this.selectedId = [];
            this.isLoading = true;
            return axios.get(this.api, {
                params: {
                    sort: {key: this.sortKey, sort_order: this.sortOrder},
                    per_page: this.per_page,
                    columnFilter: this.columnFilter,
                    search: this.search
                }
            })
                .then(({data}) => {
                    this.gridData = data;
                    this.isLoading = false;
                });
        },
        onAdd(event) {
            this.unSelectAll();
            this.$emit('add', event);
        },
        onEdit(event) {
            this.unSelectAll();
            this.$emit('edit', event);
        },
        onDelete(event) {
            this.unSelectAll();
            this.$emit('delete', event);
        },
        onCustomButton(event) {
            this.unSelectAll();
            this.$emit('customBtn', event);
        },
        onDeleteAll() {
            this.unSelectAll();
            this.$emit('deleteAll', this.selectedId);
        },
        selectAllCheckBox(source) {
            this.selectedId = [];
            const checkboxes = document.getElementsByName('gridId');
            if (!_.isNil(checkboxes)) {
                for (let i = 0, n = checkboxes.length; i < n; i++) {
                    checkboxes[i].checked = source.target.checked;
                    if (source.target.checked && checkboxes[i].value != 0) {
                        this.selectedId.push(checkboxes[i].value);
                    }
                }
            }
        },
        selectId(source) {
            this.selectedId = this.selectedId.filter(function (e) {
                return e !== source.target.value
            }.bind(this));
            if (source.target.checked) {
                this.selectedId.push(source.target.value);
            }
        },
        unSelectAll() {
            const mainId = document.getElementById('select-all');
            if (!_.isNil(mainId)) {
                mainId.checked = false;
                const items = document.getElementsByName('gridId');
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type == 'checkbox')
                        items[i].checked = false;
                }
            }
        }
    }
}
</script>
