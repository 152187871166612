<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns">
        </grid>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                searchFilterArray: [
                    {
                        label: 'From Date',
                        field: 'from_date',
                        type: Date

                    },
                    {
                        label: 'To Date',
                        field: 'to_date',
                        type: Date

                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: 'Select',
                        filterOptions: {
                            selectData: [{name: 'Approved', value: 4}, {name: 'Paid', value: 2}, {
                                name: 'Processing',
                                value: 5
                            }]
                        }
                    },

                ],
                gridColumns: [
                    {
                        label: 'Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'member ID',
                        field: 'member_id',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Request Amount',
                        field: 'amount',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Approved Date',
                        field: 'approved_date',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Paid Date',
                        field: 'paid_date',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Approved', value: "4"},
                                {name: 'Paid', value: "2"},
                                {name: 'Processing', value: 5}
                            ]
                        }
                    },
                ],
                api: '/api/payment/withdraw-summary'
            }
        },
    }
</script>
