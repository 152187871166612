<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#193857"/>
        <div class="row card-panel">
            <form class="col s12" @submit.prevent="submitFile()">
                <div class="row">
                    <div class="file-field input-field col s4">
                        <div class="btn">
                            <span>File</span>
                            <input name="files" multiple v-validate="'required'" accept=".csv" type="file" id="files"
                                   ref="files"
                                   v-on:change="handleFileUpload"/>
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate" type="text" :value="fileText">
                        </div>
                        <span v-show="errors.has('files')"
                              class="helper-text red-text">{{ errors.first('files') }}</span>
                        <span v-if="serverErrors.files" class="helper-text red-text">{{ serverErrors.files[0] }}</span>
                    </div>
                    <div class="input-field col s4">
                        <select name="category" v-validate="'required'" v-model="category">
                            <option selected disabled>Choose Category</option>
                            <option v-for="category, index in categories" :value="category.id">{{category.name}}
                            </option>
                        </select>
                        <label>Select Category</label>
                        <span v-show="errors.has('category')"
                              class="helper-text red-text">{{ errors.first('category') }}</span>
                        <span v-if="serverErrors.category"
                              class="helper-text red-text">{{ serverErrors.category[0] }}</span>
                    </div>
                    <div class="input-field col s4">
                        <button class="waves-effect waves-light btn-large" :disabled="isLoading">Upload</button>
                    </div>
                </div>
                <div class="row" v-if="files.length>0">
                    <ul class="collection with-header">
                        <li class="collection-header"><h6>Package Files({{files.length}})</h6></li>
                        <li class="collection-item" v-for="(file,index) in files" :key="index">
                            <div>
                                {{file.name}}<a href="#remove" class="secondary-content tooltipped"
                                                data-position="bottom" data-tooltip="Remove the package"
                                                @click.prevent="removeFile(index)"><i
                                    class="material-icons">close</i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PackageUpload",
        data() {
            return {
                isLoading: true,
                category: '',
                categories: [],
                serverErrors: [],
                files: [],
                fileText: '',
                data: new FormData()
            }
        },
        mounted() {
            this.fetch();
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
            const tooltip = document.querySelectorAll('.tooltipped');
            M.Tooltip.init(tooltip);
        },
        methods: {
            fetch() {
                axios.get('/api/quiz/categories')
                    .then(({data}) => {
                        this.categories = data;
                        this.isLoading = false;

                    });
            },
            handleFileUpload(e) {
                let selectedFiles = e.target.files;
                if (!selectedFiles.length) {
                    return false;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.files.push(selectedFiles[i]);
                }
                if (this.files.length > 1) {
                    this.fileText = this.files.length;
                } else {
                    this.fileText = this.files.length == 0 ? '' : this.files[0].name;
                }
            },
            removeFile(index) {
                const element = this.files.indexOf(index);
                this.files.splice(element, 1);
                if (this.files.length > 1) {
                    this.fileText = this.files.length;
                } else {
                    this.fileText = this.files.length == 0 ? '' : this.files[0].name;
                }
            },
            prepareFields() {
                for (let i = this.files.length - 1; i >= 0; i--) {
                    this.data.append("files[]", this.files[i]);
                }
                this.data.append("category", this.category);
            },
            submitFile() {
                if (this.files.length > 10) {
                    this.serverErrors = {
                        files: ['maximum uploaded packages should be less than or equal to 10']
                    }
                    console.log(this.serverErrors);
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                }
                this.serverErrors = [];
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        this.prepareFields();
                        axios.post('/api/quiz/packages',
                            this.data,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(({data}) => {
                            this.isLoading = false;
                            this.data = new FormData();
                            this.files = [];
                            this.fileText = '';
                            this.category = '';
                            this.$validator.reset();
                            return this.$message({
                                type: 'success',
                                message: 'Packages uploaded!'
                            });

                        })
                            .catch(({response}) => {
                                this.data = new FormData();
                                this.serverErrors = response.data.errors;
                                this.isLoading = false;
                            });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>