<template>
    <div>
        <div class="card">
            <div class="no-padding card-content">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
                <div class="row row-margin-bottom-0">
                    <div class="col s12">
                        <span class="card-title"> {{title}}
                            <button v-show="!isLoading" class="btn btn-flat white right grey-text" @click="getData">
                                <i class="material-icons blue-text">refresh</i>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="padding-10">
                            <bar-chart ref="bar"
                                       :labelsX="X"
                                       :labelsY="Y"
                                       :data1="today"
                                       :data2="yesterday"
                                       :api="api"
                            >
                            </bar-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default{
        name:'UsersByProvider',
        props:{
          provider:{type:String, default:''},
          title:''
        },
        data(){
            return {
                Y:['today','yesterday'],
                X:['Unverified','Verified','Active'],
                today:['0','0','0'],
                yesterday:['0','0','0'],
                api:'/api/dashboard/users-by-provider/' + this.provider,
                isLoading:false,
            }
        },
        methods:{
            getData(){
                this.isLoading = true;
                axios.get(this.api).then(({data})=> {
                    this.$refs.bar.fetch(data.data.data1, data.data.data2);
                    this.isLoading = false;
                })
                .catch(({error})=>{
                    this.isLoading = false;
                });
            }
        }
    }
</script>