<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <ul class="pagination center-align">
        <li :class="{disabled:pagination.current_page <= 1}">
            <a href="javascript:void(0)" aria-label="Previous"
               v-on:click.prevent="changePage(pagination.current_page - 1)">
                <i class="material-icons">chevron_left</i>
            </a>
        </li>
        <li class="waves-effect" v-for="page in pagesNumber" :class="{'active': page == pagination.current_page}">
            <a href="javascript:void(0)" v-on:click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li :class="{disabled:pagination.current_page >= pagination.last_page}">
            <a href="javascript:void(0)" aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 1)">
                <i class="material-icons">chevron_right</i>
            </a>
        </li>
    </ul>
</template>
<style>
    .pagination li.active {
        background-color: #5661b3;
    }
</style>
<script>

    export default {
        props: {
            pagination: {
                type: [Object, Array],
                required: true
            },
            offset: {
                type: Number,
                default: 4
            }
        },
        computed: {
            pagesNumber() {
                if (!this.pagination.to) {
                    return [];
                }
                let from = this.pagination.current_page - this.offset;
                if (from < 1) {
                    from = 1;
                }
                let to = from + (this.offset * 2);
                if (to >= this.pagination.last_page) {
                    to = this.pagination.last_page;
                }
                let pagesArray = [];
                for (let page = from; page <= to; page++) {
                    pagesArray.push(page);
                }
                return pagesArray;
            }
        },
        methods: {
            changePage(page) {
                this.pagination.current_page = page;
                this.$emit('paginate', page);
            }
        }
    }
</script>