<template>
    <div>
        <div class="card">
            <div class="no-padding card-content">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
                <span class="card-title no-margin">QUIZ DETAILS
                    <button v-show="!isLoading" class="btn btn-flat white right grey-text" @click="getData">
                    <i class="material-icons blue-text">refresh</i>
                        </button>
                </span>
                <ul class="tabs">
                    <li class="tab col s3"><a class="active" href="#quiz1">TODAY</a></li>
                    <li class="tab col s3"><a href="#quiz2">YESTERDAY</a></li>
                    <li class="tab col s3"><a href="#quiz3">THIS MONTH</a></li>
                </ul>

                <div id="quiz1" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                        <tr>
                            <th>Category</th>
                            <th>Members</th>
                            <th>Credited</th>
                        </tr>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in today">
                            <td>{{item.Category}}</td>
                            <td>{{item.Members}}</td>
                            <td>{{item.Credited}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="quiz2" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                        <tr>
                            <th>Category</th>
                            <th>Members</th>
                            <th>Credited</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in yesterday">
                            <td>{{item.Category}}</td>
                            <td>{{item.Members}}</td>
                            <td>{{item.Credited}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="quiz3" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                        <tr>
                            <th>Category</th>
                            <th>Members</th>
                            <th>Credited</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in month">
                            <td>{{item.Category}}</td>
                            <td>{{item.Members}}</td>
                            <td>{{item.Credited}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name : "QuizDetails",
        data(){
            return{
                today :[],
                yesterday :[],
                month :[],
                isLoading:false,
                api:'/api/dashboard/quiz-details'
            }
        },
        methods:{
            getData(){
                this.isLoading = true;
                axios.get(this.api).then(({data})=> {
                    console.log(data);
                    this.today = data.data.quiz_details.today;
                    this.yesterday = data.data.quiz_details.yesterday;
                    this.month = data.data.quiz_details.month;
                    this.isLoading = false;
                })
                .catch(({error})=>{
                    this.isLoading = false;
                });
            }
        }
    }
</script>
