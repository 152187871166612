<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns"
              :isCustomButton="true"
              :customButton="customButtom"
              @customBtn="onDelete"
              :isEdit="true"
              @edit="onEditItem">
        </grid>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                searchFilterArray: [
                    {
                        label: 'From Date',
                        field: 'from_date',
                        type: Date

                    },
                    {
                        label: 'To Date',
                        field: 'to_date',
                        type: Date

                    },
                    {
                        label: 'Member Id',
                        field: 'member_id',
                        type: String
                    },
                    {
                        label: 'Affiliate',
                        field: 'affiliate_id',
                        type: 'Select',
                        filterOptions: {
                            selectData: []
                        }
                    },
                    {
                        label: 'Login Type',
                        field: 'service_provider_id',
                        type: 'Select',
                        filterOptions: {
                            selectData: []
                        }
                    },


                ],
                customButtom: {
                    name: "",
                    icon: "close"
                },
                gridColumns: [
                    {
                        label: 'Date & Time',
                        field: 'created_at',
                        type: Date,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                    label: 'Member Id',
                    field: 'member_id',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Mobile',
                        field: 'mobile',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Ip Address',
                        field: 'ip_address',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'user_status',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Not Verified', value: "1"},
                                {name: 'Verified', value: "2"},
                                {name: 'Active', value: "3"},
                                {name: 'Inactive', value: "4"},
                            ]
                        }
                    },
                    {
                        label: 'Subscription',
                        field: 'subscription',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Yes', value: 1},
                                {name: 'No', value: 0},
                            ]
                        }
                    }
                ],
                api: '/api/member'
            }
        },
        mounted() {
            axios.get('/api/affiliate-provider')
                .then(({data}) => {
                    this.searchFilterArray[3].filterOptions.selectData = data.affiliates;
                    this.searchFilterArray[4].filterOptions.selectData = data.providers;
                });
        },
        methods: {
            onEditItem(user) {
                window.location.href = '/member/edit?search=' + user.email;
            },
            onDelete(item) {
                this.$parent.deactivateUser(item.id);
            }
        }
    }
</script>
