<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
        <div class="row card-panel">
            <table class="striped">
                <thead>
                <tr>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="item in categories" :class="{editing: item == edited}" v-cloak>
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        <div class="view">
                            ₹{{item.amount}}
                        </div>
                        <div class="edit">
                            <input type="text" v-validate="{ required: true}" placeholder=""
                                   class="form-control form-control-line" name="amount" v-model="item.amount">
                            <span v-show="errors.has('amount')" class="helper-text red-text">{{ errors.first('amount') }}</span>
                            <span v-if="serverErrors.amount"
                                  class="helper-text red-text">{{ serverErrors.amount[0] }}</span>
                        </div>
                    </td>
                    <td>
                        <span v-if="item.status" class="chip teal">Active</span>
                        <span class="chip red" v-else>In Active</span>
                    </td>
                    <td>
                        <div class="edit">
                            <button type="button" v-on:click="onUpdate(item)" class="btn btn-outline-success">Update
                            </button>
                            <button type="button" v-on:click="onCancel(item)" class="btn btn-outline-info">Cancel
                            </button>
                        </div>
                        <div class="view">
                            <button type="button" v-on:click="onEdit(item)" class="btn btn-sm">Edit
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CategoryAmount",
        data() {
            return {
                categories: [],
                serverErrors: [],
                isLoading: true,
                edited: null,
                _beforeEditingCache: [],
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                axios.get('/api/quiz/categories')
                    .then(({data}) => {
                        this.categories = data;
                        this.isLoading = false;

                    });
            },
            onEdit(category) {
                this._beforeEditingCache = Object.assign({}, category);
                this.edited = category;

            },
            onCancel(category) {
                Object.assign(category, this._beforeEditingCache);
                this.edited = this._beforeEditingCache = null;
            },
            onUpdate(category) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.put('/api/quiz/amount', category)
                            .then(({data}) => {
                                this.categories = data;
                                this.edited = this._beforeEditingCache = null;
                                this.isLoading = false;
                                M.toast({html: 'Amount Updated', classes: 'teal'})
                            }).catch(({response}) => {
                            this.isLoading = false;
                            M.toast({html: 'Please validate all field!', classes: 'red'})
                        });
                    }
                });

            },
        }
    }
</script>

<style scoped>
    .chip {
        color: #FFFFFF;
    }

    [v-cloak] {
        display: none;
    }

    .edit {
        display: none;
    }

    .editing .edit {
        display: block
    }

    .editing .view {
        display: none;
    }
</style>