import { render, staticRenderFns } from "./CreateFollowUp.vue?vue&type=template&id=ef11abba&scoped=true&"
import script from "./CreateFollowUp.vue?vue&type=script&lang=js&"
export * from "./CreateFollowUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef11abba",
  null
  
)

export default component.exports