<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true"
              :filter-key="searchQuery">
        </grid>
        <modal id="category-modal">
            <form @submit.prevent="onCategoryAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Categories</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="name" id="name" name="name" v-validate="'required'"
                                           v-model="name" type="text" class="validate">
                                    <label for="name">Name</label>
                                    <span class="error-text">{{ errors.first('name') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <!-- Switch -->
                                    <div class="switch">
                                        <label>
                                            In Active
                                            <input type="checkbox" v-model="status">
                                            <span class="lever"></span>
                                            Active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                modal: null,
                searchQuery: '',
                gridColumns: [{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                api: '/api/post/category',
                name: '',
                id: '',
                status: '',
                header: '',
            }
        },
        mounted() {
            const elem = document.getElementById('category-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        methods: {
            onAddItem() {
                this.id = '';
                this.name = '';
                this.status=true;
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onCategoryAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        return axios.post(this.api, {
                            name: this.name,
                            id: this.id,
                            status: this.status
                        })
                            .then(({data}) => {
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                            });
                    }
                });

            },
            onEditItem(item) {
                this.id = item.id;
                this.name = item.name;
                this.status = item.status;
                this.header = "Edit"
                this.modal.open();

            }
        }
    }
</script>
