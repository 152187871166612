<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
<li><a href="javascript:void(0);" class="dropdown-trigger1" data-target="dropdown1"><i
        class="material-icons animated swing infinite">notifications_none</i><span
        class="badge notification-badge circle pulse">{{notifications.length}}</span></a>

    <ul id="dropdown1" class="dropdown-content notification">
        <li class="header disabled" disabled><a href="#!" class="disabled"> Notifications <span
                class="new badge amber right">{{notifications.length}}</span></a></li>
        <template v-for="notification in notifications">
            <li>
                <a href="#!"><i class="material-icons cyan-text">{{notification.data.icon}}</i>
                    {{notification.data.title}}
                    <p><sub class="grey-text">{{notification.data.message}}</sub></p>
                </a>
            </li>
            <li class="divider"></li>

        </template>

    </ul>

</li>
</template>

<script>
    export default {
        name: "Notification",
        props: ['notify'],
        data() {
            return {
                notifications: []
            }
        },
        mounted() {
            this.fetch();
            const dropdown_elems = document.querySelectorAll('.dropdown-trigger1');
            M.Dropdown.init(dropdown_elems, {
                hover: false,
                coverTrigger: false,
                constrainWidth: false
            });
        },
        watch: {
            notify: function (newVal, oldVal) { // watch it
                this.notifications.unshift({
                    data: {
                        'title': newVal.title,
                        'message': newVal.message,
                        'link': newVal.link,
                        'icon': newVal.icon
                    }
                });
            }
        },
        methods: {
            fetch() {
                axios.get('/api/notify')
                    .then(({data}) => {
                        this.notifications = data.notifications;
                    });
            },
            markRead(notifyRead) {
                axios.post('/api/notify/read', {id: notifyRead})
                    .then(({data}) => {
                        this.notifications = data.notifications;
                    });
            }

        }
    }
</script>
<style scoped>
    .notification{
        width: 350px;
    }
</style>