<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <div class="row">
            <div class="col s12">
                <grid @add="onAddItem" ref="grid"
                      :api="todayAPI"
                      @edit="onEditItem"
                      :columns="todayGridColumns"
                      :isEdit="true"
                      :isAdd="false">
                </grid>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <grid @add="onAddItem" ref="grid"
                      :api="api"
                      @edit="onEditItem"
                      :columns="gridColumns"
                      :isEdit="true"
                      :isAdd="true">
                </grid>
            </div>
        </div>
    </div>
</template>

<script>
    import {serverBus} from '../../serverBus';

    export default {
        name: "AdTemplate",
        data() {
            return {

                gridColumns: [{
                    label: 'From Name',
                    field: 'from_name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Subject',
                        field: 'subject',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    }, {
                        label: 'Created',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                todayGridColumns: [{
                    label: 'From Name',
                    field: 'from_name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Subject',
                        field: 'subject',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    }, {
                        label: 'Created',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                api: '/api/advertisement/bonus-email',
                todayAPI: '/api/advertisement/bonus-email/today'
            }
        },
        mounted() {
            serverBus.$on('templateRefresh', (server) => {
                this.$refs.grid.fetch();
            });

        },
        methods: {
            onAddItem() {
                this.$router.push({
                    name: 'createBonusEmail'
                });
            },
            onEditItem(advertisement) {
                this.$router.push({
                    name: 'updateBonusEmail',
                    params: {ad: advertisement}
                });
            },
        }
    }
</script>

<style scoped>

</style>