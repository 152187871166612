<template>
    <div>
        <div class="card">
            <div class="no-padding card-content">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
                <span class="card-title">LOGIN DETAILS
                    <button v-show="!isLoading" class="btn btn-flat white right grey-text" @click="getData">
                    <i class="material-icons blue-text">refresh</i>
                </button>
                </span>
                <div class="padding-10">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                        <tr>
                            <th></th>
                            <th>Today</th>
                            <th>Yesterday</th>
                            <th>This Month</th>
                            <th>Last Month</th>
                        </tr>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in login_details">
                            <td>{{item.Category}}</td>
                            <td>{{item.today}}</td>
                            <td>{{item.yesterday}}</td>
                            <td>{{item.this_month}}</td>
                            <td>{{item.last_month}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name : "LoginDetails",
        data(){
            return{
                login_details:[],
                isLoading:false,
                api:'/api/dashboard/login-summary'
            }
        },
        methods:{
            getData(){
                this.isLoading = true;
                axios.get(this.api).then(({data})=> {
                    this.login_details = data.data.login_details;
                    this.isLoading = false;
                })
                .catch(({error})=>{
                    this.isLoading = false;
                });
            }
        }
    }
</script>