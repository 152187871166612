<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="card-panel">
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
        <table>
            <thead>
            <tr><th>Date & Time</th>
                <th>Campaign Name</th>
                <th>Type</th>
                <th>Smtp</th>
                <th>Total</th>
                <th>Opened</th>
                <th>Clicked</th>
                <th>CTR%</th>
                <th>Delivered</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr :id="campaign.id" v-for="campaign in campaigns.data">
                <td>{{campaign.created_at|formatDate}}</td>
                <td>{{campaign.advertisement.subject}}</td>
                <td>
                    <span v-if="campaign.type==1">NewsLetter</span>
                    <span v-else>FollowUp</span>
                </td>
                <td>{{campaign.smtp.name}}</td>
                <td>{{campaign.total}}</td>
                <td>{{campaign.opened}}</td>
                <td>{{campaign.clicks}}</td>
                <td v-if="campaign.delivered > 0">{{((parseInt(campaign.clicks)/parseInt(campaign.delivered))*100).toFixed(2)}}%</td>
                <td v-else>0</td>
                <td>{{campaign.delivered}}</td>
                <td v-html="status(campaign.status)"></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "NewsLetter",
        data() {
            return {
                campaigns: [],
                isLoading: true
            }
        },
        mounted() {
            this.fetch();
            let newLetter = document.getElementById('news-letter');
            newLetter.classList.add('active');
            let adTemplates = document.getElementById('ad-templates');
            adTemplates.classList.remove('active');
            let title = document.querySelectorAll('.title');
            title[0].innerHTML = 'NewsLetter';
            title[1].innerHTML = 'NewsLetter';
            // Echo Public channel
            // Echo Public channel
            this.$echo.private(`Campaign.Role.${currentUser.roles[0].id}`).listen('.Status', (e) => {
                let tr = document.getElementById(e.uid);
                if (!_.isNil(e.delivered)) {
                    tr.getElementsByTagName("td")[6].innerHTML = e.delivered;
                }
                tr.getElementsByTagName("td")[7].innerHTML = this.status(parseInt(e.status));
            }).listen('.End', (e) => {
                this.$message({
                    type: 'info',
                    message: 'Campaign Sent Successfully!'
                });
                this.fetch();
                axios.post('/api/notify', e)
                    .then(({data}) => {
                        this.$parent.notify = data.notifications;
                    });
            });
        },
        methods: {
            fetch() {
                this.isLoading = true;
                axios.get('/api/campaigns')
                    .then(({data}) => {
                        this.campaigns = data;
                        this.isLoading = false;
                    });
            },
            status(campStatus) {
                switch (campStatus) {
                    case 1:
                        return '<div class="chip white-text grey">\n' +
                            '                        SCHEDULED\n' +
                            '                    </div>';
                        break;
                    case 2:
                        return '<div class="chip white-text blue">PROCESSING<span class="loader"></span></div>';
                        break;

                    case 6:
                        return '<div class="chip white-text teal">SENDING<span class="loader"></span></div>';
                        break;

                    case 3:
                        return ' <div class="chip white-text teal"> SENT </div>';
                        break;

                    case 4:
                        return '<div class="chip white-text orange"> CANCELLED </div>';
                        break;

                    case 5:
                        return '<div class="chip white-text red"> FAILED </div>';
                        break;
                }

            }
        }
    }
</script>

<style>
    .loader {
        float: right;
        margin: 5px;
        border: 2px solid #FFFF;
        border-top: 5px solid #3498db;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
