<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid ref="grid"
              :api="api"
              :columns="gridColumns">
        </grid>
    </div>
</template>

<script>
    export default {
        name: "ErrorLog",
        data() {
            return {
                gridColumns: [{
                    label: 'Name',
                    field: 'level_name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Code',
                        field: 'level',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Message',
                        field: 'message',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Created at',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    }
                ],
                api: '/api/log'
            }
        },
    }
</script>
<style scoped>

</style>