<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onUserAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Categories</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="name" id="name" name="name" v-validate="'required'"
                                           v-model="name" type="text" class="validate">
                                    <label for="name">Name</label>
                                    <span class="error-text">{{ errors.first('name') }}</span>
                                    <span v-if="serverErrors.name" class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <!-- Switch -->
                                    <div class="switch">
                                        <label>
                                            In Active
                                            <input type="checkbox" v-model="status">
                                            <span class="lever"></span>
                                            Active
                                        </label>
                                    </div>
                                    <span v-if="serverErrors.status" class="helper-text red-text">{{ serverErrors.status[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>
<script>
    export default {
        name: 'AdCategory',
        data() {
            return {
                modal: null,
                searchQuery: '',
                gridColumns: [{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                }, {
                    label: 'Status',
                    field: 'status',
                    type: Boolean,
                    filterOptions: {
                        enabled: false,
                        type: 'select',
                        selectData: [
                            {name: 'Active', value: 1},
                            {name: 'InActive', value: 0}
                        ]
                    }
                }
                ],
                api: '/api/advertisement/category',
                name: '',
                id: '',
                status: '',
                header: '',
                serverErrors: []
            }
        },
        mounted() {
            const elem = document.getElementById('user-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        },
        methods: {
            onAddItem() {
                this.id = '';
                this.name = '';
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onUserAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        if (this.header == "Add") {
                            return axios.post(this.api, {
                                name: this.name,
                                status: this.status,
                                id: null
                            })
                                .then(({data}) => {
                                    this.close();
                                    this.$refs.grid.isLoading = true;
                                    this.$refs.grid.fetch();
                                })
                                .catch(({response}) => {
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                })
                        }
                        return axios.put(this.api, {
                            name: this.name,
                            status: this.status,
                            id: this.id
                        })
                            .then(({data}) => {
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                            }).catch(({response}) => {
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;

                                }
                            })
                    }

                });

            },
            onEditItem(item) {
                this.id = item.id;
                this.name = item.name;
                this.status = item.status;
                this.header = "Edit"
                this.modal.open();

            }
        }
    }
</script>