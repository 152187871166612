<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="container card-panel">
        <vue-element-loading :active="isViewLoading" spinner="bar-fade-scale" color="#FF6700"/>
        <div class="row">
            <form class="col s12">
                <div class="row">
                    <div class="input-field col s4">
                        <select class="select followup-email" name="day" v-model="day"
                                v-validate="'required'">
                            <option value="" disabled selected>Choose Followup Day</option>
                            <option v-for="day, index in 101" :key="index" :value="index">
                                {{index}} Day
                            </option>
                        </select>
                        <label :class="this.day? 'active':''">Day</label>
                        <span class="error-text">{{ errors.first('day') }}</span>
                    </div>
                    <div class="input-field col s4">
                        <el-time-select
                                v-model="hour"
                                :picker-options="{
                                        start: '00:00',
                                        step: '00:15',
                                        end: '24:00'
                                      }"
                        >
                        </el-time-select>
                        <label :class="this.hour? 'active':''">hour</label>
                        <span class="error-text">{{ errors.first('hour') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select followup-email" name="ad_category_id" v-model="ad_category_id"
                                @change="selectAd($event.target.value)"
                                v-validate="'required'">
                            <option value="" disabled selected>Choose Ad Category</option>
                            <option v-for="category, index in ad_categories" :value="category.value">
                                {{category.name}}
                            </option>
                        </select>
                        <label :class="this.ad_category_id? 'active':''">Ad Category</label>
                        <span class="error-text">{{ errors.first('ad_category_id') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select followup-email" name="advertisement_id" v-model="advertisement_id"
                                @change="selectTemplate"
                                v-validate="'required'">
                            <option value="" disabled selected>Choose Advertisement</option>
                            <option v-for="ad, index in ads" :value="ad.id">
                                {{ad.ad_id}}-{{ad.subject}}
                            </option>
                        </select>
                        <label :class="this.advertisement_id? 'active':''">Advertisement/Template</label>
                        <span class="error-text">{{ errors.first('advertisement_id') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select followup-email" name="smtp_setting_id" v-model="smtp_setting_id"
                                v-validate="'required'">
                            <option value="" disabled selected>Choose your SMTP option</option>
                            <option v-for="connection, index in smtpData" :value="connection.value">
                                {{connection.name}}
                            </option>
                        </select>
                        <label>SMTP</label>
                        <span v-show="errors.has('smtp_setting_id')" class="helper-text red-text">{{ errors.first('smtp_setting_id') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <p>
                            <label>
                                <input name="suppression" v-validate="'required'" type="radio" value="-1"
                                       v-model="suppression"/>
                                <span>All</span>
                            </label>
                            <label>
                                <input name="status" type="radio" value="2" v-model="suppression"/>
                                <span>Verified</span>
                            </label>
                            <label>
                                <input name="status" type="radio" value="1" v-model="suppression"/>
                                <span>Un Verified</span>
                            </label>
                            <label>
                                <input name="status" type="radio" value="3" v-model="suppression"/>
                                <span>Active(Email & Mobile Verified)</span>
                            </label>
                        </p>
                        <span v-show="errors.has('suppression')" class="helper-text red-text">{{ errors.first('suppression') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="from_name" name="from_name" v-validate="'required'"
                               v-model="from_name" type="text" class="validate">
                        <label for="from_name" :class="from_name? 'active':''">From Name</label>
                        <span class="error-text">{{ errors.first('from_name') }}</span>
                        <span v-if="serverErrors.from_name"
                              class="helper-text red-text">{{ serverErrors.from_name[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="from_email" name="from_email" v-validate="'required|email'"
                               v-model="from_email" type="text" class="validate">
                        <label for="from_email" :class="from_email? 'active':''">From Email</label>
                        <span class="error-text">{{ errors.first('from_email') }}</span>
                        <span v-if="serverErrors.from_email" class="helper-text red-text">{{ serverErrors.from_email[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="reply_to" name="reply_to" v-validate="'required|email'"
                               v-model="reply_to" type="text" class="validate">
                        <label for="reply_to" :class="reply_to? 'active':''">Reply To</label>
                        <span class="error-text">{{ errors.first('reply_to') }}</span>
                        <span v-if="serverErrors.reply_to"
                              class="helper-text red-text">{{ serverErrors.reply_to[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="subject" name="subject" v-validate="'required'"
                               v-model="subject" type="text" class="validate">
                        <label for="subject" :class="subject? 'active':''">Subject</label>
                        <span class="error-text">{{ errors.first('subject') }}</span>
                        <span v-if="serverErrors.subject"
                              class="helper-text red-text">{{ serverErrors.subject[0] }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col s8">
                        <input id="affiliate" name="affiliate" v-validate="'required'"
                               v-model="affiliate" type="text" class="validate">
                        <label for="affiliate" :class="affiliate? 'active':''">Affiliate</label>
                        <span class="error-text">{{ errors.first('affiliate') }}</span>
                        <span v-if="serverErrors.affiliate"
                              class="helper-text red-text">{{ serverErrors.affiliate[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <editor api-key="ni2jdsrs5j8s323wnta6pj2culmt7ak0oakui8sdkqaq5joo"
                            v-model="template" :toolbar="toolbar" :plugins="plugins"></editor>
                    <span v-show="errors.has('template')"
                          class="helper-text red-text">{{ errors.first('template') }}</span>
                    <span v-if="serverErrors.template"
                          class="helper-text red-text">{{ serverErrors.template[0] }}</span>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <div class="switch">
                            <label>
                                In-Active
                                <input type="checkbox" name="status" v-model="status">
                                <span class="lever"></span>
                                Active
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4 right-align" v-if="id">
                        <a class="waves-effect waves-light btn teal" @click.prevent="updateAdvertisement()"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i class="material-icons left">send</i>Update</a>
                    </div>
                    <div class="col s4 right-align" v-else>
                        <a class="waves-effect waves-light btn teal" @click.prevent="createAdvertisement()"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i class="material-icons left">send</i>Save</a>
                    </div>
                    <div class="col s4 left-align">
                        <a class="waves-effect waves-light btn red" @click.prevent="cancel"><i
                                class="material-icons left">cancel</i>Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {serverBus} from '../../serverBus';
    export default {
        name: "CreateFollowUp",
        props: ["adCategories", "ad", "smtp"],
        data() {
            return {
                toolbar:[
                    'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                    'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                    'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft placeholder'
                ],
                plugins:["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker","searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking","table contextmenu directionality emoticons  textcolor paste fullpage textcolor colorpicker textpattern placeholder"],
                isViewLoading: true,
                isLoading: false,
                ad_categories: [],
                smtpData: [],
                ads: [],
                serverErrors: [],
                suppression: '',
                id: '',
                day: '',
                hour: '',
                advertisement_id: '',
                from_name: '',
                smtp_setting_id: '',
                from_email: '',
                reply_to: '',
                subject: '',
                ad_category_id: '',
                affiliate: '',
                template: '',
                status: true
            }
        },
        created() {
            if (this.adCategories && this.smtp) {
                this.ad_categories = this.adCategories;
                this.smtpData = this.smtp;
                this.isViewLoading = false;
            } else {
                serverBus.$on('adCategories', (server) => {
                    this.ad_categories = server;
                });
                serverBus.$on('smtp', (server) => {
                    console.log(server);
                    this.smtpData = server;
                    this.isViewLoading = false;
                });
            }
        },
        updated() {
            const select = document.querySelectorAll('select.followup-email');
            M.FormSelect.init(select);

        },
        mounted() {
            if (this.ad) {
                this.dto(this.ad);
                this.selectAd(this.ad.ad_category_id);
                this.id = this.ad.id;
                this.ad_category_id = this.ad.ad_category_id;
                this.category_id = this.ad.category_id;
                this.advertisement_id = this.ad.advertisement_id;
                this.suppression = this.ad.suppression;
                this.day = this.ad.day;
                this.hour = this.ad.hour;
                this.smtp_setting_id = this.ad.smtp_setting_id;
            }
        },
        methods: {
            selectTemplate() {
                const template = _.find(this.ads, {id: this.advertisement_id});
                this.dto(template);
            },
            selectAd(id) {
                this.isViewLoading = true;
                axios.get('/api/advertisement/category/' + id)
                    .then(({data}) => {
                        this.isViewLoading = false;
                        this.ads = data;
                    });
            },
            createAdvertisement() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        return axios.post('/api/advertisement/followup', {
                            from_name: this.from_name,
                            from_email: this.from_email,
                            reply_to: this.reply_to,
                            subject: this.subject,
                            ad_category_id: this.ad_category_id,
                            affiliate: this.affiliate,
                            message: this.template,
                            suppression: this.suppression,
                            smtp_setting_id: this.smtp_setting_id,
                            advertisement_id: this.advertisement_id,
                            status: this.status,
                            day: this.day,
                            hour: this.hour
                        })
                            .then(({data}) => {
                                serverBus.$emit('templateRefresh', true);
                                this.isLoading = false;
                                this.$router.go(-1);
                                return this.$message({
                                    type: 'success',
                                    message: `FollowUp ${data.subject} Created!`
                                });
                            }).catch(({response}) => {
                                this.isLoading = false;
                                return this.$message({
                                    type: 'warning',
                                    message: 'Validation failed!'
                                });
                            });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            },
            updateAdvertisement() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        return axios.put('/api/advertisement/followup', {
                            id: this.id,
                            from_name: this.from_name,
                            from_email: this.from_email,
                            reply_to: this.reply_to,
                            subject: this.subject,
                            ad_category_id: this.ad_category_id,
                            affiliate: this.affiliate,
                            message: this.template,
                            suppression: this.suppression,
                            smtp_setting_id: this.smtp_setting_id,
                            advertisement_id: this.advertisement_id,
                            status: this.status,
                            day: this.day,
                            hour: this.hour
                        })
                            .then(({data}) => {
                                serverBus.$emit('templateRefresh', true);
                                this.isLoading = false;
                                this.$router.go(-1);
                                return this.$message({
                                    type: 'success',
                                    message: `FollowUp ${data.subject} Updated!`
                                });
                            }).catch(({response}) => {
                                this.isLoading = false;
                                return this.$message({
                                    type: 'warning',
                                    message: 'Validation failed!'
                                });
                            });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            },
            cancel() {
                this.$router.go(-1);
            },

            dto(advertisement) {
                this.from_name = advertisement.from_name;
                this.from_email = advertisement.from_email;
                this.reply_to = advertisement.reply_to;
                this.subject = advertisement.subject;
                this.affiliate = advertisement.affiliate;
                this.template = advertisement.message;
                this.status = advertisement.status;
            }
        }
    }
</script>

<style scoped>

</style>