<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="affiliate-modal">
            <form @submit.prevent="onAffiliateAdd($event)">

                <div class="modal-header">
                    <h5>{{header}} Affiliate</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="input-field col s8">
                            <input id="name" name="name" v-validate="'required'"
                                   v-model="name" type="text" class="validate">
                            <label for="name" :class="name? 'active':''">Name</label>
                            <span class="error-text">{{ errors.first('name') }}</span>
                            <span v-if="serverErrors.name"
                                  class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s8">
                            <input id="url" name="url" v-validate="'required'"
                                   v-model="url" type="text" class="validate">
                            <label for="url" :class="url? 'active':''">URL</label>
                            <span class="error-text">{{ errors.first('url') }}</span>
                            <span v-if="serverErrors.url"
                                  class="helper-text red-text">{{ serverErrors.url[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s8">
                            <select class="select" id="service_provider_id" name="service_provider_id" v-model="service_provider_id"
                                    v-validate="'required'">
                                <option value="" disabled selected>Select Service Provider</option>
                                <option value="4">
                                    Mail Pixel
                                </option>
                                <option value="2">
                                    Get Response
                                </option>
                            </select>
                            <label for="service_provider_id" :class="service_provider_id? 'active':''">Service Provider</label>
                            <span class="error-text">{{ errors.first('service_provider_id') }}</span>
                            <span v-if="serverErrors.service_provider_id"
                                  class="helper-text red-text">{{ serverErrors.service_provider_id[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <div class="switch">
                                <label>
                                    In-Active
                                    <input type="checkbox" name="status" v-model="status" v-validate="'required'">
                                    <span class="lever"></span>
                                    Active
                                </label>
                            </div>
                            <span v-show="errors.has('status')"
                                  class="helper-text red-text">{{ errors.first('status') }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" :disabled="isLoading" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>

        </modal>
    </div>
</template>

<script>
    export default {
        name: "Affiliate",
        data() {
            return {
                isLoading:false,
                header:'',
                api: '/api/affiliates',
                id: '',
                name: '',
                service_provider_id:'',
                url: 'https://homebaseworks.in',
                status: true,
                serverErrors: [],
                gridColumns: [{
                    label: 'S.no',
                    field: 'id',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Affiliate Id',
                        field: 'affiliate_id',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    }, {
                        label: 'Affiliate URL',
                        field: 'aff_signed_url',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Provider',
                        field: 'service_name',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
            }
        },
        updated() {
            const select = document.getElementById('service_provider_id');
            M.FormSelect.init(select);
        },
        mounted() {
            const elem = document.getElementById('affiliate-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        methods: {
            onAddItem() {
                this.serverErrors=[];
                this.id = '';
                this.name = '';
                this.service_provider_id='';
                this.status = true;
                this.url = 'https://www.dailyonlinejobs.com'
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onAffiliateAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.isLoading=true;
                        if (_.isEmpty(this.id) && this.id.length==0) {
                            return axios.post(this.api, {
                                name: this.name,
                                id: this.id, url: this.url, status: this.status,service_provider_id:this.service_provider_id
                            })
                                .then(({data}) => {
                                    this.isLoading =false;
                                    this.close();
                                    this.$refs.grid.isLoading = true;
                                    this.$refs.grid.fetch();
                                }).catch(({response}) => {
                                    this.isLoading = false;
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                });
                        }
                        else {
                            return axios.put(this.api, {
                                name: this.name,
                                id: this.id, url: this.url, status: this.status,service_provider_id:this.service_provider_id
                            })
                                .then(({data}) => {
                                    this.isLoading =false;
                                    this.close();
                                    this.$refs.grid.isLoading = true;
                                    this.$refs.grid.fetch();
                                }).catch(({response}) => {
                                    this.isLoading = false;
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                });
                        }
                    }
                });

            },
            onEditItem(item) {
                this.serverErrors=[];
                this.id = item.id;
                this.name = item.name;
                this.url = item.url;
                this.service_provider_id =item.service_provider_id;
                this.status = item.status;
                this.header = "Edit";
                this.modal.open();

            }
        }
    }
</script>

<style scoped>

</style>
