<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              @customBtn="onMailSend"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true"
              :isCustomButton="true"
              :customButton="customButtom">
        </grid>
    </div>
</template>

<script>
    import {serverBus} from '../../serverBus';

    export default {
        name: "AdTemplate",
        data() {
            return {
                searchQuery: '',
                categories: [],
                customButtom: {
                    name: "Send Mail",
                    icon: "send"
                },
                gridColumns: [{
                    label: 'Ad ID',
                    field: 'ad_id',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Subject',
                        field: 'subject',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Ad Category',
                        field: 'ad_category_id',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: []
                        }
                    },
                    {
                        label: 'From Name',
                        field: 'from_name',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'From Email',
                        field: 'from_email',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                api: '/api/advertisement/template',
            }
        },
        mounted() {
            serverBus.$on('templateRefresh', (server) => {
                this.$refs.grid.fetch();
            });
            axios.get('/api/advertisement/category/all')
                .then(({data}) => {
                    this.gridColumns[2].filterOptions.selectData = data;
                    serverBus.$emit('adCategories', data);
                });
        },
        methods: {
            onAddItem() {
                this.$router.push({
                    name: 'createTemplate',
                    params: {adCategories: this.gridColumns[2].filterOptions.selectData}
                });
            },
            onEditItem(advertisement) {
                this.$router.push({
                    name: 'updateTemplate',
                    params: {adCategories: this.gridColumns[2].filterOptions.selectData, ad: advertisement}
                });
            },
            onMailSend(item) {
                this.$router.push({name: 'campaign', params: {template: item}});
            }

        }
    }
</script>

<style scoped>

</style>
