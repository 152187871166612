<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onUserAdd($event)">
                <div class="modal-header">
                    <h5>{{ header }} Categories</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input :class="name? 'active':''" id="name" name="name" v-validate="'required'"
                                           v-model="name" type="text" class="validate">
                                    <label for="name">Name</label>
                                    <span class="error-text">{{ errors.first('name') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <treeselect
                                        :options="categories"
                                        :default-expand-level="1"

                                        v-model="parent_id"
                                        :show-count="true"
                                        placeholder="select parent category"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input :class="order? 'active':''" id="order" name="order" v-validate="'required'"
                                           v-model="order" type="text" class="validate">
                                    <label for="order">Order</label>
                                    <span class="error-text">{{ errors.first('order') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <!-- Switch -->
                                    <div class="switch">
                                        <label>
                                            In Active
                                            <input type="checkbox" v-model="status">
                                            <span class="lever"></span>
                                            Active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    components: {
        Treeselect
    },
    data() {
        return {
            modal: null,
            searchQuery: '',
            gridColumns: [{
                label: 'Name',
                field: 'name',
                type: String,
                filterOptions: {
                    enabled: true
                }
            },
                {
                    label: 'Status',
                    field: 'status',
                    type: Boolean,
                    filterOptions: {
                        enabled: true,
                        type: 'select',
                        selectData: [
                            {name: 'Active', value: 1},
                            {name: 'InActive', value: 0}
                        ]
                    }
                }
            ],
            api: '/api/talent/category',
            name: '',
            id: '',
            parent_id: '',
            status: 1,
            header: '',
            categories: []
        }
    },
    mounted() {
        const elem = document.getElementById('user-modal');
        this.modal = M.Modal.getInstance(elem);
        this.fetch();

    },
    updated() {
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
    },
    methods: {
        fetch(){
            axios.get('/api/talent/categories')
                .then(({data}) => {
                    this.categories = data;
                });
        },
        onAddItem() {
            this.id = '';
            this.name = '';
            this.parent_id = '';
            this.order='';
            this.header = "Add";
            this.modal.open();
            this.$validator.reset();
        },
        close() {
            this.modal.close();
        },
        onUserAdd(event) {
            this.$validator.validate().then(result => {
                if (result) {
                    return axios.post(this.api, {
                        name: this.name,
                        order:this.order,
                        id: this.id,
                        parent_id: this.parent_id,
                        status: this.status
                    })
                        .then(({data}) => {
                            this.close();
                            this.$refs.grid.isLoading = true;
                            this.$refs.grid.fetch();
                            this.fetch();
                        });
                }
            });

        },
        onEditItem(item) {
            this.id = item.id;
            this.name = item.name;
            this.parent_id = item.parent_id;
            this.status = item.status;
            this.order= item.order;
            this.header = "Edit"
            this.modal.open();

        }
    }
}
</script>
