<!--
  - /**
  -  *  * Copyright (C) Woosu Automative India Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="card-panel">
        <vue-element-loading :active="isFormLoading" spinner="bar-fade-scale" color="#5661b3"/>
        <div class="row">
            <form class="col s12">
                <div class="row">
                    <div class="input-field col s8">
                        <input id="title" name="title" type="text" v-validate="'required'" v-model="talent.title"
                               class="validate">
                        <label for="title" :class="talent.title? 'active':''">Title</label>
                        <span v-show="errors.has('title')"
                              class="helper-text red-text">{{ errors.first('title') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{ serverErrors.title[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <span>Featured Image</span>
                    <div class="col s12">
                        <div class="upload-btn-wrapper">
                            <a class="btn"><i class="material-icons right">cloud_upload</i>
                                <span v-if="talent.image.length <= 0">Add a Image</span>
                                <span v-else>change a Image</span>
                            </a>
                            <input type="file"
                                   accept="image/*"
                                   name="image" @change="previewImage"/>
                        </div>
                    </div>
                    <div class="col s12">
                        <span v-show="errors.has('image')"
                              class="helper-text red-text">{{ errors.first('image') }}</span>
                        <span v-if="serverErrors.image" class="helper-text red-text">{{ serverErrors.image[0] }}</span>
                    </div>
                </div>
                <div class="row" v-show="talent.image.length > 0">
                    <div class="col s12">
                        <div class="image-preview">
                            <img class="materialboxed preview" width="150" :src="talent.image">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <treeselect
                            :options="categories"
                            :default-expand-level="1"
                            :disable-branch-nodes="true"
                            :multiple="true"
                            :clearable="true"
                            :searchable="true"
                            v-model="talent.category_ids"
                            :show-count="true"
                            placeholder="select parent category"
                        />
                    </div>
                </div>
                <div class="row">
                    <editor api-key="5kbxfp8igvwx3f7jr8htz0l1yutofxq9gj3n3rjzl85jwtf2"
                            v-model="talent.description" :toolbar="toolbar" :plugins="plugins" :init="init"></editor>
                    <span v-show="errors.has('description')"
                          class="helper-text red-text">{{ errors.first('description') }}</span>
                    <span v-if="serverErrors.content" class="helper-text red-text">{{
                            serverErrors.description[0]
                        }}</span>
                </div>
                <div class="row">
                    <div class=" input-field col s6">
                        <input id="price" name="price" type="text" v-validate="'required'" v-model="talent.price"
                               class="validate">
                        <label for="price" :class="talent.price? 'active':''">Price</label>
                        <span v-show="errors.has('price')"
                              class="helper-text red-text">{{ errors.first('price') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{ serverErrors.price[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s6">
                        <input id="duration" name="duration" type="text" v-validate="'required'"
                               v-model="talent.duration"
                               class="validate">
                        <label for="price" :class="talent.duration? 'active':''">Duration</label>
                        <span v-show="errors.has('duration')"
                              class="helper-text red-text">{{ errors.first('duration') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{
                                serverErrors.duration[0]
                            }}</span>
                    </div>

                </div>
                <div class="row">
                    <div class="input-field col s6">
                        <input id="location" name="location" type="text" v-validate="'required'"
                               v-model="talent.location"
                               class="validate">
                        <label for="title" :class="talent.location? 'active':''">Location</label>
                        <span v-show="errors.has('location')"
                              class="helper-text red-text">{{ errors.first('location') }}</span>
                        <span v-if="serverErrors.location" class="helper-text red-text">{{
                                serverErrors.location[0]
                            }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col s6">
                        <select id="languages" multiple name="languages" v-model="talent.languages"
                                v-validate="'required'">
                            <option value="english">English</option>
                            <option value="tamil">Tamil</option>
                            <option value="hindi">Hindi</option>
                        </select>
                        <label>Status</label>
                        <span v-show="errors.has('status')"
                              class="helper-text red-text">{{ errors.first('status') }}</span>
                        <span v-if="serverErrors.status" class="helper-text red-text">{{
                                serverErrors.status[0]
                            }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col s6">
                        <select id="select" name="status" v-model="talent.status" v-validate="'required'">
                            <option value="" disabled selected>Choose your option</option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">Rejected/Deleted</option>
                        </select>
                        <label>Status</label>
                        <span v-show="errors.has('status')"
                              class="helper-text red-text">{{ errors.first('status') }}</span>
                        <span v-if="serverErrors.status" class="helper-text red-text">{{
                                serverErrors.status[0]
                            }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4">
                        <a class="waves-effect waves-light btn teal" @click.prevent="submittalent"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i
                                class="material-icons left">send</i>Submit
                            talent</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: "EditTalent",
    props: ["talentData"],
    components: {
        Treeselect
    },
    data() {
        return {
            init: {
                height: '400px',
                setup: function (editor) {
                    editor.on('NodeChange', function (e) {
                        if (e.element.tagName === "IMG") {
                            e.element.setAttribute("loading", "lazy");
                        }
                    });
                },
                image_class_list: [
                    {title: 'Image Response', value: 'responsive-img'},
                ],
                images_upload_handler: function (blobInfo, success, failure) {
                    let formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    axios.talent('/api/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(({data}) => {
                        success(data.url);
                    }).catch(({response}) => {
                        failure('Invalid JSON: ' + response.data.errors);
                        return;
                    });
                }
            },
            toolbar: [
                'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft'
            ],
            plugins: ["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking", "table contextmenu directionality emoticons  textcolor paste  textcolor colorpicker textpattern"],
            isFormLoading: true,
            isLoading: false,
            categories: [],
            serverErrors: [],
            imgSrc: '',
            talent: {
                id: '',
                category_ids: [],
                title: '',
                description: '',
                duration: '',
                price: '',
                image: '',
                languages: [],
                location: '',
                status: 1
            },
        }
    },
    methods:
        {
            toDataURL(src, callback, outputFormat) {
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    var dataURL;
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    ctx.drawImage(this, 0, 0);
                    dataURL = canvas.toDataURL(outputFormat);
                    callback(dataURL);
                };
                img.src = src;
                if (img.complete || img.complete === undefined) {
                    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                    img.src = src;
                }
            },
            priceType(event) {
                this.talent.price_type = event.target.value;
            },
            durationType(event) {
                this.talent.duration_type = event.target.value;
            },
            previewImage(event) {
                const input = event.target;
                if (input.files && input.files[0]) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.talent.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            },
            submittalent() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        if (this.talent.id) {
                            return axios.put('/api/talent', this.talent)
                                .then(({data}) => {
                                    this.isLoading = false;
                                     this.$message({
                                        type: 'success',
                                        message: `talent ${data.title} Created!`
                                    });
                                    this.$router.go(-1);
                                }).catch(({response}) => {
                                    this.isLoading = false;
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                    return this.$message({
                                        type: 'warning',
                                        message: 'Validation failed!'
                                    });
                                });
                        } else {
                            return axios.post('/api/talent', this.talent)
                                .then(({data}) => {
                                    this.isLoading = false;
                                    this.$message({
                                        type: 'success',
                                        message: `talent ${data.title} Updated!`
                                    });
                                    this.talent = data;
                                    this.talent.image = '';
                                    const src = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/storage/talents/' + this.talent.slug + '/' + this.talent.slug + '.webp';
                                    this.toDataURL(src, function (data) {
                                        this.talent.image = data;
                                    }.bind(this));
                                    this.$validator.errors.clear();
                                    return this.$router.push({
                                        name: 'editTalent',
                                        params: {talentData: data}
                                    });
                                }).catch(({response}) => {
                                    this.isLoading = false;
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                    this.$message({
                                        type: 'warning',
                                        message: 'Validation failed!'
                                    });

                                });

                        }
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            }
            ,
        }
    ,
    updated() {
        const select = document.getElementById('select');
        M.FormSelect.init(select);
        const languages = document.getElementById('languages');
        M.FormSelect.init(languages);
    }
    ,
    mounted() {
        axios.get('/api/talent/categories')
            .then(({data}) => {
                this.categories = data;
                this.isFormLoading = false;
            });
        if (this.talentData) {
            this.talent.id = this.talentData.id;
            this.talent.title = this.talentData.title;
            this.talent.description = this.talentData.description;
            this.talent.duration = this.talentData.duration;
            this.talent.price = this.talentData.price;
            this.talent.status = this.talentData.status;
            this.talent.languages = this.talentData.languages;
            this.talent.location = this.talentData.location;
            this.talentData.categories.forEach(function (item) {
                this.talent.category_ids.push(item.id);
            }.bind(this));
            this.talentData.image = '';
            const src = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/storage/talents/' + this.talentData.slug_id + '/' + this.talentData.slug_id + '.png?'+(new Date()).getTime();
            this.toDataURL(src, function (data) {
                this.talent.image = data;
            }.bind(this));
            this.$validator.errors.clear();
        }
    }
}
</script>
<style scoped>
.upload-btn-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

img.preview {
    background-color: white;
    border: 1px solid #ddd;
    padding: 3px;
}
</style>
