<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<script>
    import { Line } from 'vue-chartjs';

    export default {
        extends: Line,
        props: {
            labels:{
                type: Array,
                default: function () {
                    return ['not-set','not-set','not-set','not-set','not-set','not-set','not-set']
                }
            },
            today: {
                type: Array,
                default: function () {
                    return [0,0,0,0,0,0,0]
                }
            },
            yesterday: {
                type: Array,
                default: function () {
                    return [0,0,0,0,0,0,0]
                }
            },
        },
        data: () => ({
            chartdata: {
                datacollection: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Today',
                            backgroundColor: 'rgba(171, 71, 188, 0.4)',
                            borderColor: 'rgba(171, 71, 188, 1)',
                            data: [],
                            fill: true,
                        },
                        {
                            label: 'Yesterday',
                            backgroundColor: 'rgba(0, 191, 165, 0.4)',
                            borderColor: 'rgba(0, 191, 165, 1)',
                            data: [],
                            fill: true,
                        }]
                }
            },
            options: {
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        display: true
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Users'
                        },
                        ticks: {
                            min:0,
                            stepSize:10
                        }
                    }]
                }
            }

        }
        ),
        mounted() {
            this.chartdata.datacollection.labels = this.labels;
            this.chartdata.datacollection.datasets[0].data = this.today;
            this.chartdata.datacollection.datasets[1].data = this.yesterday;
            // Overwriting base render method with actual data.
            this.renderChart(this.chartdata.datacollection, this.options)
        }
        ,
        updated() {
            this.renderChart(this.chartdata.datacollection, this.options)
        }
    }
</script>