<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onRoleAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Role</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="name" id="name" name="name" v-validate="'required'"
                                           v-model="name" type="text" class="validate">
                                    <label for="name">Name</label>
                                    <span class="error-text">{{ errors.first('name') }}</span>
                                    <span v-if="serverErrors.name" class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="description" id="description" name="name"
                                           v-validate="'required'"
                                           v-model="description" type="text" class="validate">
                                    <label for="name">Description</label>
                                    <span class="error-text">{{ errors.first('description') }}</span>
                                    <span v-if="serverErrors.description" class="helper-text red-text">{{ serverErrors.description[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <el-select v-model="routes" filterable multiple placeholder="Select" v-validate="'required'">
                                        <el-option
                                                v-for="route  in allRoutes"
                                                :key="route.id"
                                                :label="route.name"
                                                :value="route.id">
                                        </el-option>
                                    </el-select>
                                    <label :class="allRoutes? 'active':''">Routes</label>
                                    <span v-show="errors.has('routes')" class="helper-text red-text">{{ errors.first('routes') }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" :disabled="isLoading" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" :disabled="isLoading" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
    export default {
        name: "AdminRole",
        data() {
            return {
                isLoading:false,
                modal: null,
                gridColumns: [{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                }, {
                    label: 'Description',
                    field: 'description',
                    type: String,
                    filterOptions: {
                        enabled: false,
                    }
                },
                    {
                        label: 'Routes',
                        field: 'routes',
                        type: Array,
                        filterOptions: {
                            enabled: false,
                        }
                    }
                ],
                allRoutes: [],
                api: '/api/roles',
                name: '',
                id: '',
                name: '',
                description: '',
                routes: [],
                header: '',
                serverErrors: []
            }
        },
        mounted() {
            this.fetch();
            const elem = document.getElementById('user-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        },
        methods: {
            fetch() {
                axios.get('/api/routes')
                    .then(({data}) => {
                        this.allRoutes = data;
                    });
            },
            onAddItem() {
                this.id = '';
                this.name = '';
                this.description = '';
                this.routes = [];
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onRoleAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.isLoading = true;
                        if (this.header == "Add") {
                            return axios.post(this.api, {
                                name: this.name,
                                description: this.description,
                                routes: this.routes,
                                id: null
                            })
                                .then(({data}) => {
                                    this.close();
                                    this.$refs.grid.isLoading = true;
                                    this.$refs.grid.fetch();
                                    this.isLoading = false;
                                })
                                .catch(({response}) => {
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                        this.isLoading = false;
                                    }
                                })
                        }
                        return axios.put(this.api, {
                            name: this.name,
                            description: this.description,
                            routes: this.routes,
                            id: this.id
                        })
                            .then(({data}) => {
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                                this.isLoading = false;
                            }).catch(({response}) => {
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;
                                    this.isLoading = false;

                                }
                            })
                    }

                });

            },
            onEditItem(item) {
                this.routes = _.map(item.routes, 'id');
                this.id = item.id;
                this.name = item.name;
                this.description = item.description;
                this.header = "Edit"
                this.modal.open();

            }
        }
    }
</script>

<style scoped>

</style>