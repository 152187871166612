<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="row">
        <div class="col s12">
            <grid @add="onAddItem" ref="grid"
                  :api="api"
                  @edit="onEditItem"
                  :isCustomButton="true"
                  :customButton="customButtom"
                  @customBtn="onDelete"
                  :columns="gridColumns"
                  :isEdit="true"
                  :isAdd="true">
            </grid>
        </div>
    </div>
</template>

<script>
import {serverBus} from '../../serverBus';

export default {
    name: "Post",
    data() {
        return {
            customButtom: {
                name: "Delete",
                icon: "delete"
            },
            gridColumns: [
                {
                    label: 'Title',
                    field: 'title',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: 'Categories',
                    field: 'categories',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    label: 'Created',
                    field: 'created_at',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },

            ],
            api: '/api/post/grid'
        }
    },
    mounted() {
        serverBus.$on('templateRefresh', (server) => {
            this.$refs.grid.fetch();
        });

    },
    methods: {
        onAddItem() {
            window.location = '/post/add';
        },
        onDelete(item) {
            this.$confirm('This will permanently delete the post. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$refs.grid.isLoading = true;
                axios.delete('/api/post/delete', {data: {id: item._id}})
                    .then(({data}) => {
                        this.isLoading = false;
                        this.$refs.grid.fetch();
                        return this.$message({
                            type: 'success',
                            message: `Post  ${item._id} Deleted!`
                        });
                    }).catch(({response}) => {
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        onEditItem(post) {
            this.$router.push({
                name: 'editPost',
                params: {postData: post}
            });
        },
    }
}
</script>

<style scoped>

</style>
