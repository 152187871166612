<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="terminatedUser">
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns">
        </grid>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                searchFilterArray: [
                    {
                        label: 'From Date',
                        field: 'deactivated_from',
                        type: Date

                    },
                    {
                        label: 'To Date',
                        field: 'deactivated_to',
                        type: Date

                    },
                    {
                        label: 'Email',
                        field: 'email',
                        type: String
                    }

                ],
                gridColumns: [{
                    label: 'Member Id',
                    field: 'member_id',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Mobile',
                        field: 'mobile',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Deactivated On',
                        field: 'deactivated_on',
                        type: Date,
                        filterOptions: {
                            enabled: false,
                        }
                    }
                ],
                api: '/api/member/terminated-users/list'
            }
        }
    }
</script>