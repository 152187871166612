<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onUserAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Categories</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <select v-model="advertisement.category_id" v-validate="'required'"
                                            name="category_id">
                                        <option value="" disabled selected>Choose your option</option>
                                        <option :value="item.id" v-for="item in categories">{{item.name}}</option>
                                    </select>
                                    <label>Select Category</label>
                                    <span class="error-text">{{ errors.first('category_id') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="title" name="title" v-validate="'required'"
                                           v-model="advertisement.title" type="text" class="validate">
                                    <label :class="{active:advertisement.title}" for="title">Title</label>
                                    <span class="error-text">{{ errors.first('title') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <textarea id="description" name="description" v-validate="'required'"
                                              v-model="advertisement.description" type="text"
                                              class="materialize-textarea">
                                    </textarea>
                                    <label :class="{active:advertisement.description}"
                                           for="description">Description</label>
                                    <span class="error-text">{{ errors.first('description') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <textarea id="text" name="text" v-validate="'required'"
                                              v-model="advertisement.text" type="text" class="materialize-textarea">
                                    </textarea>
                                    <label :class="{active:advertisement.text}" for="text">Text</label>
                                    <span class="error-text">{{ errors.first('text') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="condition_1" name="condition_1" v-validate="'required'"
                                           placeholder="Your member id missing on your posted AD"
                                           v-model="advertisement.condition_1" type="text" class="validate">
                                    <label :class="{active:advertisement.condition_1}" for="condition_1">Verify Keywords
                                        1</label>
                                    <span class="error-text">{{ errors.first('condition_1') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="condition_2" name="condition_2" v-validate="'required'"
                                           placeholder="Advertiser Website Link not found"
                                           v-model="advertisement.condition_2" type="text" class="validate">
                                    <label :class="{active:advertisement.condition_2}" for="condition_2">Verify Keywords
                                        2</label>
                                    <span class="error-text">{{ errors.first('condition_2') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="condition_3" name="condition_3" v-validate="'required'"
                                           placeholder="Ad Title Missing on your AD"
                                           v-model="advertisement.condition_3" type="text" class="validate">
                                    <label :class="{active:advertisement.condition_3}" for="condition_3">Verify Keywords
                                        3</label>
                                    <span class="error-text">{{ errors.first('condition_3') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="condition_4" name="condition_4" v-validate="'required'"
                                           placeholder="Some Content Missing on your AD"
                                           v-model="advertisement.condition_4" type="text" class="validate">
                                    <label :class="{active:advertisement.condition_4}" for="condition_4">Verify Keywords
                                        4</label>
                                    <span class="error-text">{{ errors.first('condition_4') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="condition_5" name="condition_5" v-validate="'required'"
                                           placeholder="Content Missing on your Link"
                                           v-model="advertisement.condition_5" type="text" class="validate">
                                    <label :class="{active:advertisement.condition_5}" for="condition_5">Verify Keywords
                                        5</label>
                                    <span class="error-text">{{ errors.first('condition_5') }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <!-- Switch -->
                                    <div class="switch">
                                        <label>
                                            In Active
                                            <input type="checkbox" v-model="advertisement.status">
                                            <span class="lever"></span>
                                            Active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
    export default {
        name: "ManageAds",
        data() {
            return {
                modal: null,
                searchQuery: '',
                gridColumns: [{
                    label: 'Advertisement Id',
                    field: 'ad_id',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Category Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Title',
                        field: 'title',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                api: '/api/copy-paste/ads',
                header: '',
                categories: [],
                advertisement: {
                    id: null,
                    ad_id: '',
                    category_id: '',
                    title: '',
                    description: '',
                    text: '',
                    condition_1: '',
                    condition_2: '',
                    condition_3: '',
                    condition_4: '',
                    condition_5: '',
                    status: ''
                }
            }
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        },
        mounted() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
            const elem = document.getElementById('user-modal');
            this.modal = M.Modal.getInstance(elem);
            axios.get('/api/jobs/category/1')
                .then(({data}) => {
                    this.categories = data;
                });
        },
        methods: {
            onAddItem() {
                this.advertisement = {id: null};
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onUserAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        return axios.post(this.api, this.advertisement)
                            .then(({data}) => {
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                            });
                    }
                });

            },
            onEditItem(item) {
                this.advertisement = {
                    id: item.id,
                    ad_id: item.ad_id,
                    category_id: item.category_id,
                    title: item.title,
                    description: item.description,
                    text: item.text,
                    condition_1: item.condition_1,
                    condition_2: item.condition_2,
                    condition_3: item.condition_3,
                    condition_4: item.condition_4,
                    condition_5: item.condition_5,
                    status: item.status
                };
                this.header = "Edit";
                this.modal.open();

            }
        }
    }
</script>

<style scoped>

</style>
