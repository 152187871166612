<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns"
        >
        </grid>

    </div>
</template>

<script>
    export default {
        name: "Summary",
        data() {
            return {
                searchFilterArray: [
                    {
                        label: 'From Date',
                        field: 'from_date',
                        type: Date

                    },
                    {
                        label: 'To Date',
                        field: 'to_date',
                        type: Date

                    },
                    {
                        label: 'Member Id',
                        field: 'member_id',
                        type: String
                    },
                    {
                        label: 'Category',
                        field: 'category_slug',
                        type: 'Select',
                        filterOptions: {
                            selectData: [
                                {name: 'Fun', value: 'fun'},
                                {name: 'Math', value: 'math'},
                                {name: 'Trivia', value: 'trivia'}
                            ]
                        }
                    },


                ],
                gridColumns: [{
                    label: 'Member Id',
                    field: 'member_id',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Category',
                        field: 'category_slug',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Amount',
                        field: 'amount',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    },
                    {
                        label: 'Completed On',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    },
                ],
                api: '/api/quiz/summary'
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>