<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="row">
        <div class="col s8 card-panel">
            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
            <div class="container">
                <div class="row">
                    <form class="col s12">
                        <div class="row">
                            <div class="input-field col s6">
                                <span>From Date</span>
                                <el-date-picker id="to-date" v-model="campaign.fromDate" name="fromDate"
                                                @change="getTotal($event)"
                                                v-validate="'required'"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptions">
                                </el-date-picker>
                                <span v-show="errors.has('fromDate')" class="helper-text red-text">{{ errors.first('fromDate') }}</span>
                            </div>
                            <div class="input-field col s6">
                                <span>To Date</span>
                                <el-date-picker v-model="campaign.toDate" name="toDate" v-validate="'required'"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptions" @change="getTotal($event)">
                                </el-date-picker>
                                <span v-show="errors.has('toDate')" class="helper-text red-text">{{ errors.first('toDate') }}</span>
                            </div>
                            <div style="margin-left: 10px">
                                Total : {{total}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <p>
                                    <label>
                                        <input name="status" v-validate="'required'" type="radio" value="-1"
                                               v-model="campaign.status" @change="getTotal"/>
                                        <span>All</span>
                                    </label>
                                    <label>
                                        <input name="status" type="radio" value="2" v-model="campaign.status"
                                               @change="getTotal"/>
                                        <span>Verified</span>
                                    </label>
                                    <label>
                                        <input name="status" type="radio" value="1" v-model="campaign.status"
                                               @change="getTotal"/>
                                        <span>Un Verified</span>
                                    </label>
                                    <label>
                                        <input name="status" type="radio" value="3" v-model="campaign.status"
                                               @change="getTotal"/>
                                        <span>Active(Email & Mobile Verified)</span>
                                    </label>
                                </p>
                                <span v-show="errors.has('status')" class="helper-text red-text">{{ errors.first('status') }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <select class="select" name="smtp" v-model="campaign.smtp" v-validate="'required'">
                                    <option value="" disabled selected>Choose your SMTP option</option>
                                    <option v-for="connection, index in smtp" :value="connection.id">
                                        {{connection.name}}
                                    </option>
                                </select>
                                <label>SMTP</label>
                                <span v-show="errors.has('smtp')" class="helper-text red-text">{{ errors.first('smtp') }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <editor api-key="5kbxfp8igvwx3f7jr8htz0l1yutofxq9gj3n3rjzl85jwtf2"
                                    v-model="campaign.template" :toolbar="toolbar" :plugins="plugins"></editor>
                            <span v-show="errors.has('template')" class="helper-text red-text">{{ errors.first('template') }}</span>
                            <span v-if="serverErrors.template" class="helper-text red-text">{{ serverErrors.template[0] }}</span>
                        </div>
                        <div class="row">
                            <div class="col s4">
                                <a class="waves-effect waves-light btn teal" @click.prevent="confirmBulkMail()"
                                   :disabled="isLoading">
                                    <div class="progress progress-btn" v-if="isLoading">
                                        <div class="indeterminate"></div>
                                    </div>
                                    <i
                                            class="material-icons left">send</i>Send
                                    Now</a>
                            </div>
                            <div class="col s4">
                                <a class="waves-effect waves-light btn orange darken-1" @click="sendTestMail"><i
                                        class="material-icons left">account_circle</i>Account</a>
                            </div>
                            <div class="col s4">
                                <a class="waves-effect waves-light btn red"><i class="material-icons left">cancel</i>Cancel</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Campaign",
        props: ['template'],
        data() {
            return {
                toolbar: [
                    'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                    'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                    'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft placeholder'
                ],
                plugins: ["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking", "table contextmenu directionality emoticons  textcolor paste fullpage textcolor colorpicker textpattern placeholder"],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() >= Date.now();
                    }
                },
                campaign: {
                    fromDate: '',
                    toDate: '',
                    template: '',
                    status: -1,
                    smtp: '',
                    advertisement_id: ''
                },
                total: 0,
                smtp: [],
                serverErrors: [],
                isLoading: false
            };
        },
        updated() {
            this.$nextTick(() => {
                const select = document.querySelectorAll('.select');
                M.FormSelect.init(select);
            });

        },
        mounted() {
            this.fetch();
            if (typeof(this.template) == "undefined") {
                return this.$router.go(-1);
            }
            this.campaign.template = this.template.message;
            this.campaign.advertisement_id = this.template.id;
        },
        methods: {
            sendTestMail() {
                this.$validator.validate('smtp', this.campaign.smtp).then((value) => {
                    if (value) {
                        this.$prompt('Please input your e-mail', 'Send Test Mail', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                            inputErrorMessage: 'Invalid Email'
                        }).then(({value}) => {
                            this.isLoading =true;
                            this.campaign.testEmail = value;
                            axios.post('/api/campaign/test-mail', this.campaign)
                                .then(({data}) => {
                                    this.isLoading =false;
                                    this.$message({
                                        type: 'success',
                                        message: 'Test Mail Sent'
                                    });

                                })
                                .catch(({response}) => {
                                    this.isLoading =false;
                                    this.$message({
                                        type: 'warning',
                                        message: 'something wrong Please try again......'
                                    });
                                });
                        }).catch(() => {
                            this.isLoading =false;
                            this.$message({
                                type: 'info',
                                message: 'Send Test Mail cancelled!'
                            });
                        });
                    } else {
                        this.isLoading =false;
                        this.$message({
                            type: 'warning',
                            message: 'Please select smtp!'
                        });
                    }
                });
            },
            fetch() {
                axios.get('/api/smtp')
                    .then(({data}) => {
                        this.smtp = data;
                    });
            },
            getTotal() {
                if (!_.isEmpty(this.campaign.fromDate) && !_.isEmpty(this.campaign.toDate)) {
                    this.isLoading = true;
                    axios.get('/api/campaign/user-count', {
                        params: {
                            fromDate: this.campaign.fromDate,
                            toDate: this.campaign.toDate,
                            status: this.campaign.status
                        }
                    })
                        .then(({data}) => {
                            this.total = data;
                            this.isLoading = false;
                        });
                }
            },
            confirmBulkMail() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        return this.$confirm('Do you Want to Sent NewsLetter. Continue?', 'Warning', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning'
                        }).then(() => {
                            this.addCampaign();
                            this.$message({
                                type: 'success',
                                message: 'Creating Campaign'
                            });
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: 'NewsLetter Cancelled'
                            });
                        });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });

                });
            },
            addCampaign() {
                this.isLoading = true;
                axios.post('/api/campaign/create', this.campaign)
                    .then(({data}) => {
                        this.$message({
                            type: 'success',
                            message: 'Sending......'
                        });
                        this.$router.push({name: "newsletter"});
                    })
                    .catch(({response}) => {
                        this.isLoading = false;
                        if (response.status == 421) {
                            this.serverErrors = response.data.errors;
                        }
                    });
            }


        }
    }
</script>

<style scoped>
    .el-date-editor--date {
        padding-left: 14% !important;
        padding-right: 84px;

    }
</style>
