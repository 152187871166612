<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid ref="grid"
              :api="api"
              :columns="gridColumns"
              :isEdit="false"
              :isAdd="false">
        </grid>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                modal: null,
                searchQuery: '',
                gridColumns: [{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Created',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                api: '/api/jobs/types',
                name: '',
                id: '',
                header: ''
            }
        }
    }
</script>