<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="modal modal-fixed-footer">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                modal: null
            }
        },
        mounted() {
            const elems = document.querySelectorAll('.modal');
            this.modal = M.Modal.init(elems);
        }
    }
</script>