<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <div class="card">
            <div class="no-padding card-content">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
                <span class="card-title no-margin">JOB DETAILS
                    <button v-show="!isLoading" class="btn btn-flat white right grey-text" @click="getData">
                    <i class="material-icons blue-text">refresh</i>
                </button>
                </span>
                <ul class="tabs">
                    <li class="tab col s3"><a class="active" href="#job1">Links</a></li>
                    <li class="tab col s3"><a href="#job2">Bonus Emails</a></li>
                </ul>

                <div id="job1" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Pending</th>
                            <th>Approved</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in links">
                            <td>{{item.Duration}}</td>
                            <td>{{item.Pending}}</td>
                            <td>{{item.Approved}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="job2" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                        <tr>
                            <th></th>
                            <th>Requested</th>
                            <th>Clicked</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in bonusMails">
                            <td>{{item.Duration}}</td>
                            <td>{{item.Pending}}</td>
                            <td>{{item.Approved}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name : "JobDetails",
        data(){
            return{
                links :[],
                bonusMails:[],
                isLoading:false,
                api:'/api/dashboard/job-details'
            }
        },
        methods:{
            getData(){
                this.isLoading = true;
                axios.get(this.api).then(({data})=> {
                    this.links = data.data.links;
                    this.bonusMails = data.data.bonus_emails;
                    this.isLoading = false;
                })
                .catch(({error})=>{
                    this.isLoading = false;
                });
            }
        }
    }
</script>
