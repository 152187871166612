<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
        <modal id="user-modal">
            <form @submit.prevent="onRoleAdd($event)">
                <div class="modal-header">
                    <h5>{{header}} Role</h5>
                </div>
                <div class="modal-content">
                    <div class="row">
                        <div class="col s12">
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="name" id="name" name="name" v-validate="'required'"
                                           v-model="user.name" type="text" class="validate">
                                    <label for="name">Name</label>
                                    <span class="error-text">{{ errors.first('name') }}</span>
                                    <span v-if="serverErrors.name" class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="email" id="email" name="email"
                                           v-validate="'required|email'"
                                           v-model="user.email" type="text" class="validate">
                                    <label for="name">Email</label>
                                    <span class="error-text">{{ errors.first('email') }}</span>
                                    <span v-if="serverErrors.email" class="helper-text red-text">{{ serverErrors.email[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="mobile" id="mobile" name="mobile"
                                           v-validate="{required:true,regex:/^\d{10}$/}"
                                           v-model="user.mobile" type="text" class="validate">
                                    <label for="name">Mobile</label>
                                    <span class="error-text">{{ errors.first('mobile') }}</span>
                                    <span v-if="serverErrors.mobile" class="helper-text red-text">{{ serverErrors.mobile[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input placeholder="password" id="password" name="password"
                                           v-validate="password"
                                           v-model="user.password" type="password" class="validate">
                                    <label for="name">Password</label>
                                    <span class="error-text">{{ errors.first('password') }}</span>
                                    <span v-if="serverErrors.password" class="helper-text red-text">{{ serverErrors.password[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s6">
                                    <select class="select"  name="role" id="role" v-model="user.role"
                                            v-validate="'required'">
                                        <option v-for="role, index in roles" :value="role.id">
                                            {{role.name}}
                                        </option>
                                    </select>
                                    <label :class="roles? 'active':''">Roles</label>
                                    <span v-show="errors.has('role')" class="helper-text red-text">{{ errors.first('role') }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#!" class="waves-effect waves-green btn-flat" :disabled="isLoading" @click.prevent="close">Cancel</a>
                    <button class="btn waves-effect waves-light" type="submit" :disabled="isLoading" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
    export default {
        name: "CreateStaff",
        data() {
            return {
                isLoading:false,
                header:'Add',
                roles: [],
                user: {
                    id:'',
                    name: '',
                    email: '',
                    role: '',
                    mobile: '',
                    password:''
                },
                modal: null,
                gridColumns: [{
                    label: 'Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                }, {
                    label: 'Role',
                    field: 'roles',
                    type: Array,
                    filterOptions: {
                        enabled: false,
                    }
                }
                ],
                api: '/api/role/user',
                serverErrors: []
            }
        },
        computed: {
            // a computed getter
            password: function () {
               if(this.header == 'Add'){
                   return 'required';
               }
               return '';

            }
        },
        mounted() {
            this.fetch();
            const elem = document.getElementById('user-modal');
            this.modal = M.Modal.getInstance(elem);
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        },
        methods: {
            fetch() {
                axios.get('/api/roles')
                    .then(({data}) => {
                        this.roles = data.data;
                    });
            },
            onAddItem() {
                this.user.id = '';
                this.user.name = '';
                this.user.email='';
                this.user.mobile='';
                this.user.role='';
                this.user.password='';
                this.header = "Add";
                this.modal.open();
                this.$validator.reset();
            },
            close() {
                this.modal.close();
            },
            onRoleAdd(event) {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.isLoading =true;
                        if (this.header == "Add") {
                            return axios.post(this.api, this.user)
                                .then(({data}) => {
                                    this.close();
                                    this.$refs.grid.isLoading = true;
                                    this.$refs.grid.fetch();
                                    this.isLoading=false;
                                })
                                .catch(({response}) => {
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                        this.isLoading = false;
                                    }
                                })
                        }
                        return axios.put(this.api, this.user)
                            .then(({data}) => {
                                this.isLoading =false;
                                this.close();
                                this.$refs.grid.isLoading = true;
                                this.$refs.grid.fetch();
                            }).catch(({response}) => {
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;
                                    this.isLoading = false;

                                }
                            })
                    }

                });

            },
            onEditItem(item) {
                this.user.id = item.id;
                this.user.name = item.name;
                this.user.email=item.email;
                this.user.mobile=item.mobile;
                this.user.role=item.roles[0].id;
                this.header = "Edit";
                this.modal.open();

            }
        }
    }
</script>

<style scoped>

</style>