<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div id="demo">
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns"
              :isCustomButton="true"
              :selectable="true"
              :customButton="customButtom"
              @customBtn="onDelete"
              @deleteAll="onDeleteAll"
              :isEdit="true"
              @edit="onEditItem">
        </grid>

    </div>
</template>
<script>
export default {
    data() {
        return {
            searchFilterArray: [
                {
                    label: 'From Date',
                    field: 'from_date',
                    type: Date

                },
                {
                    label: 'To Date',
                    field: 'to_date',
                    type: Date

                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'Select',
                    filterOptions: {
                        selectData: [{name: 'Approved', value: 1}, {name: 'Pending', value: 2}, {
                            name: 'Delete',
                            value: 3
                        }]
                    }
                },


            ],
            customButtom: {
                name: "Delete",
                icon: "delete"
            },
            gridColumns: [{
                label: 'Member Id',
                field: 'member_id',
                type: String,
                filterOptions: {
                    enabled: true
                }
            },
                {
                    label: 'Subject',
                    field: 'title',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },

                {
                    label: 'Created at',
                    field: 'created_at',
                    type: String,
                    filterOptions: {
                        enabled: true,
                    }
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'Select',
                    filterOptions: {
                        enabled: true,
                        type: 'select',
                        selectData: [
                            {name: 'Approved', value: "1"},
                            {name: 'Pending', value: "2"},
                            {name: 'Delete', value: "3"},
                        ]
                    }
                },
            ],
            api: '/api/article'
        }
    },
    mounted() {

    },
    methods: {
        onEditItem(article) {
            this.$router.push({
                name: 'editArticle',
                params: {articleData: article}
            });
        },
        onDeleteAll(items) {
            this.$prompt('Please input your Reject Reason', 'Reject', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^[a-z\d\-_\s]+$/i,
                inputErrorMessage: 'Please input valid reason'
            }).then(({value}) => {
                this.$refs.grid.isLoading = true;
                axios.delete('/api/article', {data: {id: items, reason: value}})
                    .then(({data}) => {
                        this.isLoading = false;
                        this.$refs.grid.fetch();
                        return this.$message({
                            type: 'success',
                            message: `Article Deleted!`
                        });
                    }).catch(({response}) => {
                    return this.$message({
                        type: 'success',
                        message: `Seems Something Wrong! Contact Satz`
                    });
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Request Canceled'
                });
            });

        },
        onDelete(item) {
            this.$prompt('Please input your Reject Reason', 'Reject', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^[a-z\d\-_\s]+$/i,
                inputErrorMessage: 'Please input valid reason'
            }).then(({value}) => {
                this.$refs.grid.isLoading = true;
                item.status = 3;
                axios.put('/api/article', {id: item._id, status: 3, reason: value})
                    .then(({data}) => {
                        this.isLoading = false;
                        this.$refs.grid.fetch();
                        return this.$message({
                            type: 'success',
                            message: `Article  ${data.title} Deleted!`
                        });
                    }).catch(({response}) => {

                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Request Canceled'
                });
            });
        }
    }
}
</script>
