<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="container card-panel">
        <div class="row">
            <form class="col s12">
                <div class="row">
                    <div class="input-field col s8">
                        <input id="from_name" name="from_name" v-validate="'required'"
                               v-model="from_name" type="text" class="validate">
                        <label for="from_name" :class="from_name? 'active':''">From Name</label>
                        <span class="error-text">{{ errors.first('from_name') }}</span>
                        <span v-if="serverErrors.from_name"
                              class="helper-text red-text">{{ serverErrors.from_name[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="from_email" name="from_email" v-validate="'required|email'"
                               v-model="from_email" type="text" class="validate">
                        <label for="from_email" :class="from_email? 'active':''">From Email</label>
                        <span class="error-text">{{ errors.first('from_email') }}</span>
                        <span v-if="serverErrors.from_email" class="helper-text red-text">{{ serverErrors.from_email[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="reply_to" name="reply_to" v-validate="'required|email'"
                               v-model="reply_to" type="text" class="validate">
                        <label for="reply_to" :class="reply_to? 'active':''">Reply To</label>
                        <span class="error-text">{{ errors.first('reply_to') }}</span>
                        <span v-if="serverErrors.reply_to"
                              class="helper-text red-text">{{ serverErrors.reply_to[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="subject" name="subject" v-validate="'required'"
                               v-model="subject" type="text" class="validate">
                        <label for="subject" :class="subject? 'active':''">Subject</label>
                        <span class="error-text">{{ errors.first('subject') }}</span>
                        <span v-if="serverErrors.subject"
                              class="helper-text red-text">{{ serverErrors.subject[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <p>
                            <label>
                                <input name="payment_type" type="radio" v-validate="'required'" value="1"
                                       v-model="payment_type"/>
                                <span>Pay Per Click</span>
                            </label>
                            <label>
                                <input name="payment_type" type="radio" value="2" v-model="payment_type"/>
                                <span>Post Back Pay</span>
                            </label>
                        </p>
                        <span v-show="errors.has('payment_type')" class="helper-text red-text">{{ errors.first('payment_type') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select" id="adcategory" name="ad_category" v-model="ad_category"
                                v-validate="'required'">
                            <option value="" disabled selected>Choose Ad Category</option>
                            <option v-for="category, index in ad_categories" :value="category.value">
                                {{category.name}}
                            </option>
                        </select>
                        <label :class="this.ad_category? 'active':''">Ad Category</label>
                        <span class="error-text">{{ errors.first('ad_category') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="affiliate" name="affiliate" v-validate="'required'"
                               v-model="affiliate" type="text" class="validate">
                        <label for="affiliate" :class="affiliate? 'active':''">Affiliate</label>
                        <span class="error-text">{{ errors.first('affiliate') }}</span>
                        <span v-if="serverErrors.affiliate"
                              class="helper-text red-text">{{ serverErrors.affiliate[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <textarea id="address" name="address" v-validate="'required'"
                                  v-model="address" type="text" class="materialize-textarea validate"></textarea>
                        <label for="address" :class="address? 'active':''">Address</label>
                        <span class="error-text">{{ errors.first('address') }}</span>
                        <span v-if="serverErrors.address"
                              class="helper-text red-text">{{ serverErrors.address[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="link_url" name="link_url" v-validate="'required|url'"
                               v-model="link_url" type="text" class="validate">
                        <label for="link_url" :class="link_url? 'active':''">Link URL</label>
                        <span class="error-text">{{ errors.first('link_url') }}</span>
                        <span v-if="serverErrors.link_url"
                              class="helper-text red-text">{{ serverErrors.link_url[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <editor api-key="5kbxfp8igvwx3f7jr8htz0l1yutofxq9gj3n3rjzl85jwtf2"
                            v-model="template" :toolbar="toolbar" :plugins="plugins"></editor>
                    <span v-show="errors.has('template')"
                          class="helper-text red-text">{{ errors.first('template') }}</span>
                    <span v-if="serverErrors.template"
                          class="helper-text red-text">{{ serverErrors.template[0] }}</span>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="credit_amount" name="credit_amount" v-validate="'required'"
                               v-model="credit_amount" type="text" class="validate">
                        <label for="credit_amount" :class="credit_amount? 'active':''">Credit Amount</label>
                        <span class="error-text">{{ errors.first('credit_amount') }}</span>
                        <span v-if="serverErrors.credit_amount"
                              class="helper-text red-text">{{ serverErrors.credit_amount[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <div class="switch">
                            <label>
                                In-Active
                                <input type="checkbox" name="status" v-model="status">
                                <span class="lever"></span>
                                Active
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4 right-align" v-if="id">
                        <a class="waves-effect waves-light btn teal" @click.prevent="updateAdvertisement()"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i class="material-icons left">send</i>Update</a>
                    </div>
                    <div class="col s4 right-align" v-else>
                        <a class="waves-effect waves-light btn teal" @click.prevent="createAdvertisement()"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i class="material-icons left">send</i>Save</a>
                    </div>
                    <div class="col s4 left-align">
                        <a class="waves-effect waves-light btn red" @click.prevent="cancel"><i
                                class="material-icons left">cancel</i>Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {serverBus} from '../../serverBus';
    export default {
        name: "CreateAdvertisement",
        props: ["adCategories", "ad"],
        data() {
            return {
                toolbar:[
                    'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                    'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                    'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft placeholder'
                ],
                plugins:["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker","searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking","table contextmenu directionality emoticons  textcolor paste fullpage textcolor colorpicker textpattern placeholder"],
                isLoading: false,
                ad_categories: [],
                serverErrors: [],
                id: '',
                from_name: '',
                from_email: '',
                reply_to: '',
                subject: '',
                payment_type: '',
                ad_category: '',
                affiliate: '',
                template: '',
                link_url: '',
                credit_amount: '',
                address: '',
                status: true,
            }
        },
        created() {
            if (this.adCategories) {
                this.ad_categories = this.adCategories;
            } else {
                serverBus.$on('adCategories', (server) => {
                    this.ad_categories = server;
                });
            }

        },
        updated() {
            const select = document.getElementById('adcategory');
            M.FormSelect.init(select);
        },
        mounted() {
            if (this.ad) {
                this.dto(this.ad);
            }
        },
        methods: {
            createAdvertisement() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        return axios.post('/api/advertisement', {
                            from_name: this.from_name,
                            from_email: this.from_email,
                            reply_to: this.reply_to,
                            subject: this.subject,
                            payment_type: this.payment_type,
                            ad_category_id: this.ad_category,
                            affiliate: this.affiliate,
                            message: this.template,
                            link_url: this.link_url,
                            credit_amount: this.credit_amount,
                            address: this.address,
                            status: this.status,
                        })
                            .then(({data}) => {
                                serverBus.$emit('templateRefresh', true);
                                this.isLoading = false;
                                this.$router.go(-1);
                            }).catch(({response}) => {
                                this.isLoading = false;
                                return this.$message({
                                    type: 'warning',
                                    message: 'Validation failed!'
                                });
                            });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            },
            updateAdvertisement() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        return axios.put('/api/advertisement', {
                            id: this.id,
                            from_name: this.from_name,
                            from_email: this.from_email,
                            reply_to: this.reply_to,
                            subject: this.subject,
                            payment_type: this.payment_type,
                            ad_category_id: this.ad_category,
                            affiliate: this.affiliate,
                            message: this.template,
                            link_url: this.link_url,
                            credit_amount: this.credit_amount,
                            address: this.address,
                            status: this.status,
                        })
                            .then(({data}) => {
                                serverBus.$emit('templateRefresh', true);
                                this.isLoading = false;
                                this.$router.go(-1);
                                return this.$message({
                                    type: 'success',
                                    message: `Advertisement ${data.ad_id} Updated!`
                                });
                            }).catch(({response}) => {
                                this.isLoading = false;
                                return this.$message({
                                    type: 'warning',
                                    message: 'Validation failed!'
                                });
                            });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            },
            cancel() {
                this.$router.go(-1);
            },

            dto(advertisement) {
                this.id = advertisement.id;
                this.from_name = advertisement.from_name;
                this.from_email = advertisement.from_email;
                this.reply_to = advertisement.reply_to;
                this.subject = advertisement.subject;
                this.payment_type = advertisement.payment_type;
                this.ad_category = advertisement.ad_category_id;
                this.affiliate = advertisement.affiliate;
                this.template = advertisement.message;
                this.link_url = advertisement.link_url;
                this.credit_amount = advertisement.credit_amount;
                this.address = advertisement.address;
                this.status = advertisement.status;
            }
        }
    }
</script>

<style scoped>

</style>
