<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <div class="card">
            <div class="no-padding card-content">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
                <span class="card-title no-margin">AFFILIATES
                    <button v-show="!isLoading" class="btn btn-flat white right grey-text" @click="getData">
                                <i class="material-icons blue-text">refresh</i>
                            </button>
                </span>
                <ul class="tabs">
                    <li class="tab col s3"><a class="active" href="#affiliate1">Today</a></li>
                    <li class="tab col s3"><a href="#affiliate2">yesterday</a></li>
                </ul>

                <div id="affiliate1" class="padding-10 summary-table">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                            <th>Affiliate</th>
                            <th>Active</th>
                            <th>Verified</th>
                            <th>Unverified</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in today">
                            <td>{{item.Affiliate}}</td>
                            <td>{{item.Active}}</td>
                            <td>{{item.Verified}}</td>
                            <td>{{item.Unverified}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="affiliate2" class="padding-10 summary-table">
                    <table class="responsive-table striped centered">
                        <thead>
                        <tr>
                            <th>Affiliate</th>
                            <th>Active</th>
                            <th>Verified</th>
                            <th>Unverified</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in yesterday">
                            <td>{{item.Affiliate}}</td>
                            <td>{{item.Active}}</td>
                            <td>{{item.Verified}}</td>
                            <td>{{item.Unverified}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UsersByAffiliate",
        data() {
            return {
                today: [],
                yesterday: [],
                isLoading: false,
                api: '/api/dashboard/users-by-affiliate'
            }
        },
        methods: {
            getData() {
                this.isLoading = true;
                axios.get(this.api).then(({data}) => {
                    this.today = data.data.filter(x => x.Period == "TODAY");
                    this.yesterday = data.data.filter(x => x.Period == "YESTERDAY");
                    this.isLoading = false;
                })
                    .catch(({error}) => {
                        this.isLoading = false;
                    });
            }
        }
    }
</script>
