<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid ref="todaygrid"
              api="/api/quiz/today"
              @edit="onEditItem"
              :columns="gridColumnsToday"
              :isEdit="true"
              :customButton="customButtom">
        </grid>
        <grid ref="grid"
              :api="api"
              @edit="onEditItem"
              @delete="onDeleteItem"
              :columns="gridColumns"
              :isEdit="true"
              :isDelete="true"
              :customButton="customButtom">
        </grid>
    </div>
</template>

<script>
    export default {
        name: "PackagesGrid",
        data() {
            return {
                categories: [],
                customButtom: {
                    name: "View",
                    icon: "pageview"
                },
                gridColumns: [{
                    label: 'Package Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Category',
                        field: 'category_id',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: []
                        }
                    },
                    {
                        label: 'Quiz Date',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                gridColumnsToday: [{
                    label: 'Package Name',
                    field: 'name',
                    type: String,
                    filterOptions: {
                        enabled: false
                    }
                },
                    {
                        label: 'Category',
                        field: 'category_id',
                        type: 'Select',
                        filterOptions: {
                            enabled: false,
                            selectData: []
                        }
                    },
                    {
                        label: 'Quiz Date',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: false,
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]

                        }
                    }
                ],
                api: '/api/quiz/packages',
            }
        },
        mounted() {
            axios.get('/api/quiz/category/all')
                .then(({data}) => {
                    this.gridColumns[1].filterOptions.selectData = data;
                    this.gridColumnsToday[1].filterOptions.selectData = data;
                });
        },
        methods: {
            onEditItem(item) {
                this.$router.push({
                    name: 'editPackage',
                    params: {packageItem: item, categoryItems: this.gridColumns[1].filterOptions.selectData}
                })
            },
            onDeleteItem(item) {
                return this.$confirm('Do you Want to Delete a Package. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deletePackage(item);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Cancelled'
                    });
                });
            },
            deletePackage(item) {
                axios.delete('/api/quiz/packages', {data: item})
                    .then(({data}) => {
                        this.$refs.grid.fetch();
                        this.$refs.todaygrid.fetch();
                        this.$message({
                            type: 'success',
                            message: 'Package Deleted'
                        });
                    });
            }

        }
    }
</script>

<style scoped>

</style>