<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
        <div class="row card-panel">
            <form @submit.prevent="onEditPackage($event)">
                <div class="modal-header">
                    <h5>{{package.name}} Package</h5>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="row">
                            <div class="input-field col s6">
                                <input placeholder="name" id="name" name="name" v-validate="'required'"
                                       v-model="package.name" type="text" class="validate">
                                <label for="name" :class="package.name? 'active':''">Name</label>
                                <span class="error-text">{{ errors.first('name') }}</span>
                                <span v-if="serverErrors.name"
                                      class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s6">
                                <select name="category_id" v-validate="'required'" v-model="package.category_id">
                                    <option selected disabled>Choose Category</option>
                                    <option v-for="category, index in categories" :value="category.value">
                                        {{category.name}}
                                    </option>
                                </select>
                                <label>Select Category</label>
                                <span v-show="errors.has('category_id')"
                                      class="helper-text red-text">{{ errors.first('category_id') }}</span>
                                <span v-if="serverErrors.category_id"
                                      class="helper-text red-text">{{ serverErrors.category_id[0] }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s3">
                                <span>Quiz Date</span>
                                <el-date-picker id="to-date" v-model="package.created_at" name="package.created_at"
                                                v-validate="'required'"
                                                type="date"
                                                value-format="yyyy-MM-dd">
                                </el-date-picker>
                                <span v-show="errors.has('created_at')" class="helper-text red-text">{{ errors.first('created_at') }}</span>
                                <span v-if="serverErrors.created_at"
                                      class="helper-text red-text">{{ serverErrors.created_at[0] }}</span>
                            </div>
                            <div class="input-field col s3">
                                <!-- Switch -->
                                <div class="switch">
                                    <label>
                                        In Active
                                        <input type="checkbox" v-model="package.status">
                                        <span class="lever"></span>
                                        Active
                                    </label>
                                </div>
                                <span v-if="serverErrors.created_at"
                                      class="helper-text red-text">{{ serverErrors.status[0] }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="file-field input-field col s4">
                                <div class="btn">
                                    <span>File</span>
                                    <input name="file" multiple accept=".csv" type="file"
                                           id="file" ref="file"
                                           v-on:change="handleFileUpload"/>
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text">
                                </div>
                                <span v-show="errors.has('file')"
                                      class="helper-text red-text">{{ errors.first('file') }}</span>
                                <span v-if="serverErrors.file"
                                      class="helper-text red-text">{{ serverErrors.file[0] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button class="btn waves-effect waves-light" type="submit" name="action">Submit
                        <i class="material-icons right">send</i>
                    </button>
                    <button class="btn waves-effect waves-light" type="button" @click.prevent="downloadCSV"
                            name="action">Download
                        <i class="material-icons right">send</i>
                    </button>
                </div>
            </form>
        </div>
        <div class="row card-panel">
            <table class="striped">
                <thead>
                <tr>
                    <th>Question</th>
                    <th>Answer</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="question in questions" :class="{editing: question == edited}" v-cloak>
                    <td>
                        <div class="view">
                            {{question.question}}
                        </div>
                        <div class="input-field edit">
                            <textarea name="question" v-validate="{ required: true}"
                                      class="validate materialize-textarea" v-model="question.question"></textarea>
                            <span v-show="errors.has('question')" class="helper-text red-text">{{ errors.first('question') }}</span>
                            <span v-if="questionErrors.question" class="helper-text red-text">{{ questionErrors.question[0] }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="view">
                            {{question.answer}}
                        </div>
                        <div class="input-field edit">
                            <input name="answer" v-validate="{ required: true}"
                                   type="text" class="validate" v-model="question.answer">
                            <span v-show="errors.has('answer')" class="helper-text red-text">{{ errors.first('answer') }}</span>
                            <span v-if="serverErrors.answer"
                                  class="helper-text red-text">{{ serverErrors.answer[0] }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="edit">
                            <button type="button" v-on:click="update(question,$event)" class="btn btn-sm teal">Update
                            </button>
                            <button type="button" v-on:click="cancel(question,$event)" class="btn btn-sm red">Cancel
                            </button>
                        </div>
                        <div class="view">
                            <button type="button" v-on:click="edit(question,$event)" class="btn btn-sm">Edit
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["packageItem", "categoryItems"],
        name: "EditPackage",
        data() {
            return {
                isLoading: false,
                edited: null,
                _beforeEditingCache: [],
                serverErrors: [],
                questionErrors: [],
                categories: [],
                file: [],
                questions: [],
                package: {
                    id: '',
                    name: '',
                    created_at: '',
                    category_id: '',
                    status: ''
                }
            }
        },
        computed: {
            status() {
                return this.package.status;
            }
        },
        watch: {
            status(newStatus, oldStatus) {
                if (newStatus) {
                    return this.package.status = 1;
                }
                return this.package.status = 0;
            }

        },
        mounted() {
            if (this.packageItem) {
                this.categories = this.categoryItems;
                this.package = this.packageItem;
                this.fetch();
                return;
            }
            return this.$router.go(-1);
        },
        updated() {
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);
        },
        methods: {
            fetch() {
                this.isLoading = true;
                axios.get('/api/quiz/questions/' + this.package.id)
                    .then(({data}) => {
                        this.questions = data;
                        this.isLoading = false;

                    });
            },
            edit(question) {
                this._beforeEditingCache = Object.assign({}, question);
                this.edited = question;
            },
            cancel(question) {
                Object.assign(question, this._beforeEditingCache);
                this.edited = this._beforeEditingCache = null;
            },
            update(question, $event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.put('/api/quiz/question', question)
                            .then(({data}) => {
                                this.questions = data;
                                this.isLoading = false;
                            })
                            .catch(({response}) => {
                                this.questionErrors = response.data.errors;
                                this.isLoading = false;
                            });
                    }
                });
            },
            downloadCSV() {
                this.isLoading = true;
                axios({
                    method: 'post',
                    url: '/api/quiz/package/csv',
                    responseType: 'blob',
                    data: {id: this.package.id}
                }).then((response) => {
                    this.isLoading = false;
                    if (response.data.type != 'application/json') {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.package.name + '.csv');
                        link.click();
                        window.URL.revokeObjectURL(url);
                        return new Toast('Order CSV Downloading.....', Toast.SUCCESS)
                    }
                })
                    .catch(({response}) => {
                        this.isLoading = false;
                        this.$message({
                            type: 'error',
                            message: 'Server Error/Not Found!'
                        });
                    });
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0];
            },
            onEditPackage(event) {
                this.serverErrors = [];
                let formData = new FormData();
                formData.append('id', this.package.id);
                formData.append('file', this.file);
                formData.append('name', this.package.name);
                formData.append('status', this.package.status);
                formData.append('category_id', this.package.category_id);
                formData.append('created_at', this.package.created_at);
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.post('/api/quiz/package/update', formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(({data}) => {
                                this.$message({
                                    type: 'success',
                                    message: 'Package Updated!'
                                });
                                this.questions = data;
                                this.isLoading = false;
                            })
                            .catch(({response}) => {
                                this.isLoading = false;
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;
                                }
                            });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }

    .edit {
        display: none;
    }

    .editing .edit {
        display: block
    }

    .editing .view {
        display: none;
    }
</style>
