<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
        <div class="row card-panel">
            <table class="striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="mail in mails">
                    <td>
                        {{mail.name}}
                    </td>
                    <td>
                        {{mail.subject}}
                    </td>
                    <td>
                        <span v-if="mail.status" class="chip teal">Active</span>
                        <span class="chip red" v-else>In Active</span>
                    </td>
                    <td>
                        <button type="button" v-on:click="edit(mail)" class="btn btn-sm">Edit
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <transition name="fade">
            <div class="row card-panel" v-show="isEdit">
                <form class="col s12">
                    <div class="row">
                        <div class="input-field col s3">
                            <input id="name" name="name" type="text" v-validate="{ required: true}" v-model="mail.name"
                                   class="validate" disabled>
                            <label :class="mail.name? 'active':''" for="name">Name</label>
                            <span v-show="errors.has('name')"
                                  class="helper-text red-text">{{ errors.first('name') }}</span>
                            <span v-if="serverErrors.name"
                                  class="helper-text red-text">{{ serverErrors.name[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s3">
                            <input id="sender_name" name="sender_name" type="text" v-validate="{ required: true}"
                                   v-model="mail.sender_name" class="validate">
                            <label :class="mail.sender_name? 'active':''" for="name">Sender Name</label>
                            <span v-show="errors.has('sender_name')" class="helper-text red-text">{{ errors.first('sender_name') }}</span>
                            <span v-if="serverErrors.name" class="helper-text red-text">{{ serverErrors.sender_name[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s3">
                            <input id="sender_mail" name="name" type="text" v-validate="{ required: true}"
                                   v-model="mail.sender_mail" class="validate">
                            <label :class="mail.sender_mail? 'active':''" for="name">Sender Mail</label>
                            <span v-show="errors.has('sender_mail')" class="helper-text red-text">{{ errors.first('sender_mail') }}</span>
                            <span v-if="serverErrors.sender_mail" class="helper-text red-text">{{ serverErrors.sender_mail[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <input name="subject" id="subject" type="text" v-validate="{ required: true}"
                                   v-model="mail.subject" class="validate">
                            <label :class="mail.subject? 'active':''" for="subject">Subject</label>
                            <span v-show="errors.has('subject')" class="helper-text red-text">{{ errors.first('subject') }}</span>
                            <span v-if="serverErrors.subject"
                                  class="helper-text red-text">{{ serverErrors.subject[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <editor api-key="5kbxfp8igvwx3f7jr8htz0l1yutofxq9gj3n3rjzl85jwtf2"
                                    v-model="mail.content" :toolbar="toolbar" :plugins="plugins"></editor>
                            <span v-show="errors.has('content')" class="helper-text red-text">{{ errors.first('content') }}</span>
                            <span v-if="serverErrors.content"
                                  class="helper-text red-text">{{ serverErrors.content[0] }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <div class="switch">
                                <label>
                                    In-Active
                                    <input type="checkbox" name="status" v-model="mail.status">
                                    <span class="lever"></span>
                                    Active
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <button type="submit" @click.prevent="onUpdate()" class="btn btn-sm teal">Save</button>
                            <button type="submit" @click.prevent="onCancel(mail)" class="btn btn-sm red">Cancel</button>
                        </div>
                    </div>

                </form>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "MailTemplate",
        data() {
            return {
                toolbar:[
                    'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                    'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                    'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft placeholder'
                ],
                plugins:["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker","searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking","table contextmenu directionality emoticons  textcolor paste fullpage textcolor colorpicker textpattern placeholder"],
                mails: [],
                serverErrors: [],
                isLoading: true,
                isEdit: false,
                _beforeEditingCache: [],
                mail: {
                    id: '',
                    name: '',
                    sender_name: '',
                    sender_mail: '',
                    subject: '',
                    content: '',
                    status: ''
                },
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                axios.get('/api/settings/mail-template')
                    .then(({data}) => {
                        this.mails = data;
                        this.isLoading = false;

                    });
            },
            edit(mail) {
                this._beforeEditingCache = Object.assign({}, mail);
                this.edited = mail;
                this.isEdit = true;
                this.mail = mail;
            },
            onCancel(mail) {
                Object.assign(mail, this._beforeEditingCache);
                this._beforeEditingCache = null;
                this.isEdit = false;
                this.mail = {};
            },
            onUpdate() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.post('/api/settings/mail-template', this.mail)
                            .then(({data}) => {
                                this.isEdit = false;
                                this.isLoading = false;
                                M.toast({html: 'Mail Template Updated', classes: 'teal'})
                            }).catch(({response}) => {
                            this.isLoading = false;
                            M.toast({html: 'Please validate all field!', classes: 'red'})
                        });
                    }
                });

            },
        }

    }
</script>

<style scoped>
    .chip {
        color: #FFFFFF;
    }

    textarea {
        width: 100% !important;
        height: 200px !important;
        border: 1px solid #ccc;
        border-top: none;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
