<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="card-panel">
        <div class="row">
            <div class="col s12">
                <a class="waves-effect waves-light btn" @click.prevent="goBack">Back</a>
            </div>
        </div>
        <vue-element-loading :active="isFormLoading" spinner="bar-fade-scale" color="#5661b3"/>
        <div class="row">
            <form class="col s12">
                <div class="row">
                    <div class="input-field col s8">
                        <input id="title" name="title" type="text" v-validate="'required'" v-model="article.title"
                               class="validate">
                        <label for="title" :class="article.title? 'active':''">Title</label>
                        <span v-show="errors.has('title')"
                              class="helper-text red-text">{{ errors.first('title') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{ serverErrors.title[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select" id="category_id" name="category_id" v-model="article.category_id"
                                v-validate="'required'">
                            <option v-for="category, index in categories" :value="category.id">
                                {{category.name}}
                            </option>
                        </select>
                        <label :class="article.category_id? 'active':''">Article Category</label>
                        <span class="error-text">{{ errors.first('category_id') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <input id="slug" name="slug" type="text" v-validate="'required'" v-model="article.slug"
                               class="validate">
                        <label for="slug" :class="article.slug? 'active':''">Slug</label>
                        <span v-show="errors.has('slug')" class="helper-text red-text">{{ errors.first('slug') }}</span>
                        <span v-if="serverErrors.slug" class="helper-text red-text">{{ serverErrors.slug[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <editor api-key="5kbxfp8igvwx3f7jr8htz0l1yutofxq9gj3n3rjzl85jwtf2"
                            v-model="article.content" :toolbar="toolbar" :plugins="plugins" :init="init"></editor>
                    <span v-show="errors.has('content')"
                          class="helper-text red-text">{{ errors.first('content') }}</span>
                    <span v-if="serverErrors.content" class="helper-text red-text">{{ serverErrors.content[0] }}</span>
                </div>
                <div class="row">
                    <div class="input-field col s8">
                        <select class="select" id="status" name="status" v-model="article.status"
                                v-validate="'required'">
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">Rejected/Delete</option>
                        </select>
                        <label for="slug" :class="article.status? 'active':''">Status</label>
                        <span v-show="errors.has('status')"
                              class="helper-text red-text">{{ errors.first('status') }}</span>
                        <span v-if="serverErrors.status"
                              class="helper-text red-text">{{ serverErrors.status[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4">
                        <a class="waves-effect waves-light btn teal" @click.prevent="submitArticle"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i
                                    class="material-icons left">send</i>Submit
                            Article</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EditArticle",
        props: ["articleData"],
        data() {
            return {
                init: {
                    height: '400px',
                    image_class_list: [
                        {title: 'Image Reponse', value: 'responsive-img'},
                    ],
                    images_upload_handler: function (blobInfo, success, failure) {
                        let formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        axios.post('/api/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(({data}) => {
                            success(data.url);
                        }).catch(({response}) => {
                            failure('Invalid JSON: ' + response.data.errors);
                            return;
                        });
                    }
                },
                toolbar: [
                    'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                    'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | insertdatetime preview | forecolor backcolor',
                    'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft'
                ],
                plugins: ["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking", "table contextmenu directionality emoticons  textcolor paste  textcolor colorpicker textpattern"],
                isFormLoading: true,
                isLoading: false,
                categories: [],
                serverErrors: [],
                article: {
                    id: '',
                    category_id: '',
                    title: '',
                    slug: '',
                    content: '',
                    status: ''
                },
            }
        },
        updated() {
            const select = document.querySelectorAll('.select');
            M.FormSelect.init(select);
        },
        mounted() {
            axios.get('/api/jobs/category/4')
                .then(({data}) => {
                    this.isFormLoading = false;
                    this.categories = data;
                });
            if (this.articleData) {
                this.articleData.id = this.articleData._id;
                this.article = this.articleData;
                this.$validator.errors.clear();
            }
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            submitArticle() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.put('/api/article', this.article)
                            .then(({data}) => {
                                this.isLoading = false;
                                this.$router.go(-1);
                                return this.$message({
                                    type: 'success',
                                    message: `Article  ${data._id} Updated!`
                                });
                            }).catch(({response}) => {
                            this.isLoading = false;
                            this.serverErrors = response.data.errors;
                            return this.$message({
                                type: 'warning',
                                message: 'Validation failed!'
                            });
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
