/*
 * *
 *  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
 *  *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  *  * Proprietary and confidential
 *  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
 *  *
 *
 */
const vue = require('vue');
export const serverBus = new vue();