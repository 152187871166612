/*
 * *
 *  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
 *  *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  *  * Proprietary and confidential
 *  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
 *  *
 *
 */
require('./bootstrap');
window.Vue = require('vue');

import VueElementLoading from 'vue-element-loading';
import VueRouter from 'vue-router';
import VeeValidate from 'vee-validate';
import CreateAdvertisement from './components/advertisement/CreateAdvertisement';
import CreateFollowUp from './components/advertisement/CreateFollowUp';
import CreateBonusEmail from './components/bonusemail/CreateBonusEmail';
import EditPackage from './components/quiz/EditPackage';
import Campaign from './components/advertisement/Campaign';
import NewsLetter from './components/advertisement/NewsLetter';
import {DatePicker, Message, MessageBox, Option, Select, TimeSelect} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueEcho from 'vue-echo';
import Editor from '@tinymce/tinymce-vue';
import EditPost from './components/post/EditPost';
import EditArticle from './components/article/EditArticle';
import EditTalent from "./components/talent/EditTalent";
/*Vue.use(VueEcho, {
    broadcaster: 'socket.io',
    host: window.location.hostname
}); */
Vue.use(Select);
Vue.use(Option);
locale.use(lang);
let carousel_instances = null;
let sidenav_instance = null;
document.addEventListener('DOMContentLoaded', function () {
    const carousel_elems = document.querySelectorAll('.carousel');
    carousel_instances = M.Carousel.init(carousel_elems, {
        fullWidth: true
    });
    const tooltip = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltip);
    const sidenav_elem = document.querySelector('.sidenav');
    M.Sidenav.init(sidenav_elem, {});

    const scrollspy_elems = document.querySelectorAll('.scrollspy');
    M.ScrollSpy.init(scrollspy_elems, {
        scrollOffset: 50
    });

    const dropdown_elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(dropdown_elems, {
        hover: false,
        coverTrigger: false,
        constrainWidth: true
    });

    const collapsible_elems = document.querySelectorAll('.collapsible-menu');
    M.Collapsible.init(collapsible_elems);

    const datepicker_elems = document.querySelectorAll('.datepicker');
    M.Datepicker.init(datepicker_elems, {format: 'dd-mm-yyyy', autoClose: true});

    let tab_elems = document.querySelectorAll('.tabs');
    M.Tabs.init(tab_elems, {});

    autoplay();
});

export function fnToggleSideNav() {
    if (sidenav_instance.isOpen) {
        sidenav_instance.close();
        document.querySelector('main').classList.add('container-fullwidth', 'animated', 'fadeInRight', 'faster');
        document.getElementById(sidenav_instance.id).classList.remove('animated', 'fadeInLeft', 'faster');
    } else {
        sidenav_instance.open();
        document.getElementById(sidenav_instance.id).classList.add('animated', 'fadeInLeft', 'faster');
        document.querySelector('main').classList.remove('container-fullwidth', 'animated', 'fadeInRight', 'faster');
    }
}

export function autoplay() {
    carousel_instances.forEach(function (element) {
        element.next();
    }, this);
    setTimeout(autoplay, 4000);
}

Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(VeeValidate);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('grid', require('./components/Grid').default);
Vue.component('member', require('./components/member/Member').default);
Vue.component('terminated-user', require('./components/member/TerminatedUser').default);
Vue.component('modal', require('./components/Modal').default);
Vue.component('jobtype', require('./components/jobs/JobType').default);
Vue.component('category', require('./components/jobs/Category').default);
Vue.component('ad-category', require('./components/advertisement/AdCategory').default);
Vue.component('ad-template', require('./components/advertisement/AdTemplate').default);
Vue.component('manage-ads', require('./components/copypaste/ManageAds').default);
Vue.component('webcrawler-error', require('./components/copypaste/WebCrawlerError').default);
Vue.component('mail-template', require('./components/mailtemplates/MailTemplate').default);
Vue.component('category-amount', require('./components/quiz/CategoryAmount').default);
Vue.component('package-upload', require('./components/quiz/PackageUpload').default);
Vue.component('package-grid', require('./components/quiz/PackagesGrid').default);
Vue.component('edit-post', EditPost);
Vue.component('post-category', require('./components/post/PostCategory').default);
Vue.component('followup', require('./components/advertisement/FollowUp').default);
Vue.component('bonus-email', require('./components/bonusemail/BonusEmail').default);
Vue.component('payment-earnings', require('./components/payment/Earnings').default);
Vue.component('post', require('./components/post/Post').default);
Vue.component('admin-role', require('./components/admin-role/CreateRole').default);
Vue.component('create-staff', require('./components/admin-role/CreateStaff').default);
Vue.component('notification', require('./components/notification/Notification').default);
Vue.component('editor', Editor);
Vue.component('bar-chart', require('./components/chart/BarChart').default);
Vue.component('line-chart', require('./components/chart/LineChart').default);
Vue.component('affiliate', require('./components/advertisement/Affiliate').default);
Vue.component('quiz-summary', require('./components/quiz/Summary').default);
Vue.component('withdraw-summary', require('./components/payment/PaymentSummary').default);
Vue.component('post-article', require('./components/article/Article').default);
Vue.component('error-log', require('./components/error-log/ErrorLog').default);
Vue.component('block-domain', require('./components/copypaste/BlockDomain').default);
Vue.component('users-bar', require('./components/admin-dashboard/UsersByProvider').default);
Vue.component('users-affiliate', require('./components/admin-dashboard/UsersByAffiliate').default);
Vue.component('job-details', require('./components/admin-dashboard/JobDetails').default);
Vue.component('quiz-summary', require('./components/admin-dashboard/QuizDetails').default);
Vue.component('unpaid-summary', require('./components/admin-dashboard/UnpaidSummary').default);
Vue.component('top-earner-summary', require('./components/admin-dashboard/EarningSummary').default);
Vue.component('login-summary', require('./components/admin-dashboard/LoginSummary').default);
Vue.component('talent-category', require('./components/talent/Category').default);
Vue.component('talent',require('./components/talent/Talent').default);
Vue.component('hire-talent',require('./components/talent/HireTalent.vue').default);

Vue.filter('formatDate', function (value) {
    const date = new Date(value);
    const month = date.toLocaleString("en-US", {month: 'short'});
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getDate() + ' ' + month + ' ' + date.getFullYear() + " " + strTime;
});

const routes = [
    {path: '/advertisement/template/create', component: CreateAdvertisement, name: 'createTemplate', props: true},
    {path: '/advertisement/template/update', component: CreateAdvertisement, name: 'updateTemplate', props: true},
    {path: '/advertisement/template/campaign', component: Campaign, name: 'campaign', props: true},
    {path: '/advertisement/template/newsletter', component: NewsLetter, name: 'newsletter', props: true},
    {path: '/advertisement/followup/create', component: CreateFollowUp, name: 'createFollowUp', props: true},
    {path: '/advertisement/followup/update', component: CreateFollowUp, name: 'updateFollowUp', props: true},
    {path: '/advertisement/bonus-email/create', component: CreateBonusEmail, name: 'createBonusEmail', props: true},
    {path: '/advertisement/bonus-email/update', component: CreateBonusEmail, name: 'updateBonusEmail', props: true},
    {path: '/quiz/manage-packages/edit', component: EditPackage, name: 'editPackage', props: true},
    {path: '/post/edit', component: EditPost, name: 'editPost', props: true},
    {path: '/article/edit', component: EditArticle, name: 'editArticle', props: true},
    {path: '/talent/edit', component: EditTalent, name: 'editTalent', props: true},
    {path: '/talent/add', component: EditTalent, name: 'addTalent', props: true},
];
const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: "link-active", // active class for non-exact links.
    linkExactActiveClass: "link-active" // active class for *exact* links.
});

function errorResponseHandler(error) {
    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error);
    }

    // if has response show the error
    if (error.response) {
        switch (error.response.status) {
            case 422:
                M.toast({html: 'Please validate all field!', classes: 'red'});
                break;
            case 500:
                M.toast({html: 'Server Error!', classes: 'red'});
                break;
        }
    }
    return Promise.reject(error);
}

// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);


const app = new Vue({
    el: '#app',
    router,
    data() {
        return {
            notify: '',
            serverErrors: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() >= Date.now();
                }
            },
            fromDate: '',
            toDate: '',
            links: [],
            link: '',
            smtp: [],
            sms: [],
            month: '',
            modalInstance: null,

            isLoading: true,
            isRouting: false,
            linkReason: {
                status: '',
                links: [],
                reason: ''
            }
        }
    },
    updated() {
        const select = document.getElementById('mselect');
        M.FormSelect.init(select);
    },
    mounted() {
        const select = document.getElementById('mselect');
        M.FormSelect.init(select);
        if (this.$route.name === null) {
            this.isRouting = false;
        } else {
            this.isRouting = true;
        }
        const elem = document.getElementById('modal');
        if (elem != null) {
            this.modalInstance = M.Modal.getInstance(elem);
        }
        if (this.$route.query.fromDate) {
            this.fromDate = this.$route.query.fromDate;
        }
        if (this.$route.query.toDate) {
            this.toDate = this.$route.query.toDate;
        }
        let urlParams = new URLSearchParams(window.location.search);
        this.month = urlParams.get('month');
        this.fromDate = urlParams.get('fromDate');
        this.toDate = urlParams.get('toDate');
    },
    watch: {
        $route(to, from) {
            if (this.$route.name != null && this.$route.name.length > 0) {
                this.isRouting = true;
            } else {
                this.isRouting = false;
            }
        }
    },
    methods: {
        selectCheckbox(source) {
            const e = source.target;
            const id = e.id.replace('ad-', '');
            if (e.checked) {
                document.getElementById(id).value = 1;
                return;
            }
            document.getElementById(id).value = 0;
        },
        menuToggle() {
            fnToggleSideNav();
        },
        onSmtp(id) {
            this.errors.clear();
            this.isLoading = true;
            axios.get('/api/settings/smtp/' + id)
                .then(({data}) => {
                    this.smtp = data;
                    this.isLoading = false;
                });
            this.modalInstance.open();
        },
        onSmtpEdit(event) {
            this.$validator.validate().then(result => {
                if (result) {
                    this.isLoading = true;
                    axios.put('/api/settings/smtp/' + this.smtp.id, this.smtp)
                        .then(({data}) => {
                            M.toast({html: 'Saved Successfully!', class: 'teal'});
                            location.reload();
                        });
                }
            });
        },
        onSms(id) {
            this.errors.clear();
            this.isLoading = true;
            axios.get('/api/settings/sms/' + id)
                .then(({data}) => {
                    this.sms = data;
                    this.isLoading = false;
                });
            this.modalInstance.open();
        },
        onSmsEdit(event) {
            this.$validator.validate().then(result => {
                if (result) {
                    this.isLoading = true;
                    axios.put('/api/settings/sms/' + this.sms.id, this.sms)
                        .then(({data}) => {
                            M.toast({html: 'Saved Successfully!', class: 'teal'});
                            location.reload();
                        });
                }
            });
        },
        toggleCheckboxAllLinks(source) {
            this.links = [];
            const checkboxes = document.getElementsByName('link');
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                checkboxes[i].checked = source.target.checked;
                if (source.target.checked && checkboxes[i].value != 0) {
                    this.links.push(checkboxes[i].value);
                }
            }
        },
        toggleCheckboxLink(source) {
            console.log(source);
            if (!source.target.checked) {
                this.links = this.links.filter(function (e) {
                    return e !== source.target.value;
                });
            }
        },
        onLinkSubmit(event) {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    (event.target).submit();
                }
            });
        },
        paymentReceipts(event) {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    (event.target).submit();
                }
            });
        },
        updateComment(objectId, status) {
            this.$confirm('This will change the comment status. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                axios.put('/api/comment/' + status, {object_id: objectId})
                    .then(({data}) => {
                        if (Array.isArray(data)) {
                            data.forEach(function (item) {
                                let row = document.getElementById(item);
                                if (row) {
                                    row.parentNode.removeChild(row);
                                }
                            });
                        }
                        let rows = document.getElementById(objectId);
                        if (rows) {
                            rows.parentNode.removeChild(rows);
                        }
                        this.$message({
                            type: 'success',
                            message: 'Comment Status Updated'
                        });
                    });
            });
        },
        updateReviewWriting(id, status) {
            this.$confirm('This will change the comment status. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                axios.put('/api/review/' + status, {id: id})
                    .then(({data}) => {
                        if (Array.isArray(data)) {
                            data.forEach(function (item) {
                                let row = document.getElementById(item);
                                if (row) {
                                    row.parentNode.removeChild(row);
                                }
                            });
                        }
                        let rows = document.getElementById(id);
                        if (rows) {
                            rows.parentNode.removeChild(rows);
                        }
                        this.$message({
                            type: 'success',
                            message: 'Review Writing Status Updated'
                        });
                    });
            });
        },
        payment(paymentId, status, reason) {
            axios.put('/api/payment/status', {payment_id: paymentId, status: status, reason: reason})
                .then(({data}) => {
                    let row = document.getElementById('payment-' + paymentId);
                    row.parentNode.removeChild(row);
                    this.$message({
                        type: 'success',
                        message: 'Payment Request Status Updated'
                    });
                });
        },
        approvePayment(paymentId, status) {
            this.$confirm('This will approve the payment request. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.payment(paymentId, status, null);
                this.$message({
                    type: 'success',
                    message: 'Payment Request Approving....'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Payment Request canceled'
                });
            });
        },
        rejectPayment(paymentId, status) {
            this.$prompt('Please input your Reject Reason', 'Reject', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^[a-z\d\-_\s]+$/i,
                inputErrorMessage: 'Please input valid reason'
            }).then(({value}) => {
                this.payment(paymentId, status, value);
                this.$message({
                    type: 'success',
                    message: 'Payment Request Rejecting....'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Request Canceled'
                });
            });
        },
        deactivateUser(userId) {
            this.$confirm('This will deactivate the user. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                axios.put('/api/member/deactivate', {user_id: userId})
                    .then(({data}) => {
                        this.$message({
                            type: 'success',
                            message: 'Deactivation Successful'
                        });
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Deactivation Cancelled'
                });
            });
        },
    }
});


//Tiny MCE Editor Plugin
const placeholder = ['name', 'email', 'mobile', 'subject', 'amt', 'click_earnings',
    'paid_earnings', 'referal_count', 'total_earnings', 'earnings',
    'referal_earnings', 'copypaste_earnings'];
let items = [];

tinymce.PluginManager.add('placeholder', function (editor) {
    tinymce.each(placeholder, function (languageName) {
        items.push({
            text: languageName,
            onclick: function () {
                editor.insertContent('[' + languageName + ']');
            }
        });
    });

    editor.addButton('placeholder', {
        type: 'menubutton',
        text: 'Placeholder',
        icon: 'layers',
        menu: items
    });

});
