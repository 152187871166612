<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid ref="grid"
              :api="api"
              :searchFilterArray="searchFilterArray"
              :columns="gridColumns">
        </grid>
    </div>
</template>

<script>
    import {serverBus} from '../../serverBus';
    export default {
        name: "AdminEarnings",
        data() {
            return {
                searchQuery: '',
                searchFilterArray: [
                    {
                        label: 'Minimum Amount',
                        field: 'unpaid',
                        type: String
                    }

                ],
                gridColumns: [
                    {
                    label: 'Member ID',
                    field: 'member_id',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Name',
                        field: 'name',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Registered',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: false,
                        }
                    },
                    {
                        label: 'Unpaid',
                        field: 'unpaid',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                ],
                api: '/api/payment/earnings',
            }
        }
    }
</script>

<style scoped>

</style>