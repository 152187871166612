<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <grid @add="onAddItem" ref="grid"
              :api="api"
              @edit="onEditItem"
              :columns="gridColumns"
              :isEdit="true"
              :isAdd="true">
        </grid>
    </div>
</template>

<script>
    import {serverBus} from '../../serverBus';

    export default {
        name: "FollowUp",
        data() {
            return {
                searchQuery: '',
                categories: [],
                gridColumns: [{
                    label: 'Day',
                    field: 'day',
                    type: String,
                    filterOptions: {
                        enabled: true
                    }
                },
                    {
                        label: 'Hours',
                        field: 'hour',
                        type: String,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'SMTP',
                        field: 'smtp_setting_id',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: []
                        }
                    },
                    {
                        label: 'Ad Category',
                        field: 'ad_category_id',
                        type: 'Select',
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: []
                        }
                    },
                    {
                        label: 'Subject',
                        field: 'subject',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'From Name',
                        field: 'from_name',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'From Email',
                        field: 'from_email',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Created',
                        field: 'created_at',
                        type: String,
                        filterOptions: {
                            enabled: true,
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: Boolean,
                        filterOptions: {
                            enabled: true,
                            type: 'select',
                            selectData: [
                                {name: 'Active', value: 1},
                                {name: 'InActive', value: 0}
                            ]
                        }
                    }
                ],
                api: '/api/advertisement/followup',
            }
        },
        mounted() {
            serverBus.$on('templateRefresh', (server) => {
                this.$refs.grid.fetch();
            });
            axios.get('/api/advertisement/category/all')
                .then(({data}) => {
                    this.categories = data;
                    this.gridColumns[3].filterOptions.selectData = data;
                    serverBus.$emit('adCategories', data);
                });
            axios.get('/api/smtp/all')
                .then(({data}) => {
                    this.gridColumns[2].filterOptions.selectData = data;
                    serverBus.$emit('smtp', data);
                });
        },
        methods: {
            onAddItem() {
                this.$router.push({
                    name: 'createFollowUp',
                    params: {
                        adCategories: this.categories,
                        smtp: this.gridColumns[2].filterOptions.selectData
                    }
                });
            },
            onEditItem(advertisement) {
                this.$router.push({
                    name: 'updateFollowUp',
                    params: {
                        adCategories: this.categories,
                        ad: advertisement,
                        smtp: this.gridColumns[2].filterOptions.selectData
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
